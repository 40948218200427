import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import Register2 from "./Register2.js";
import { isEmpty, capitalizeWords, sendpost, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import UserFilePanel from "./UserFilePanel.js";
import { ajaxFail } from '../GlobalState.js';
import axios from "axios";

export default function Register3(props) {
    const [filds, setDynamicFields] = useState(null);
    var dynamicdata = {};
    var dynamicFields = null;
    const [captcha, setCaptcha] = useState("");
    var [globalData, setGlobalData] = useGlobalState('globalData');

    useEffect(() => {

        getFieldList();
        fetchgetcaptcha();
        fetchGeneralData();
    }, [])

    const getFieldList = () => {
        fetch("getuserfields", {
            method: 'GET',
            headers: {
                'Accept': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setDynamicFields(data);

                });
            }
        });
    }


    const fetchGeneralData = () => {
        fetch("getgeneraldata", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setGlobalData(data);
                });
            }
        });
    }

    const fetchgetcaptcha = () => {
        fetch("getcaptcha", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.text().then(async data => {
                    setCaptcha(data.replaceAll('"', ""));
                    const script = document.createElement("script");
                    script.src = "https://www.google.com/recaptcha/api.js";
                    script.async = true;
                    script.defer = true;
                    document.getElementById("myscript").appendChild(script);
                });
            }
        });
    }






    const handleDynamicChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        dynamicdata[target.name] = { "value": value, "type": target.type };
    }

    if (filds != null) { //!!!!!dont set the dynamicFields in fetch repoonce. it is in another scope and not see the handlechange
        dynamicFields = <div className="mb-3 mt-3 row">
            {filds.map(rec => <div className="col-12 col-md-6 text-start">
                <label htmlFor={rec.name} className="form-label m-0">{rec.name}</label><br />
                {rec.type != "select" ?
                    <input type={rec.type} className={rec.type != "checkbox" ? "form-control" : "form-check-input"} id={rec.name} name={rec.name} defaultValue={dynamicdata[rec.name] != null ? dynamicdata[rec.name] : rec.initValue} onChange={handleDynamicChange} />
                    :
                    <select className="form-select form-select-sm" id={rec.name} name={rec.name} placeholder="Soru Türü" onChange={handleDynamicChange} >
                        <option value=""></option>
                        {rec.initValue != null ? rec.initValue.split(",").map(rec =>
                            <option value={rec}>{rec}</option>) : ""}
                    </select>

                }
            </div>
            )}

        </div>
    }



    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        window.userdata[target.name] = value;
    }


    const onSubmit = (e) => {
        e.preventDefault();
        var check1 = document.getElementById("registerAgrement");
        var check2 = document.getElementById("registerKVKK");
        if (check1 != null && check1.checked == false) {
            showErrorMessage("Zorunlu metinler kabul edilmelidir.");
            return;
        }
        if (check2 != null && check2.checked == false) {
            showErrorMessage("Zorunlu metinler kabul edilmelidir.");
            return;
        }

        window.userdata.name = capitalizeWords(window.userdata.name);
        window.userdata.family = capitalizeWords(window.userdata.family);


        window.userdata.dynamicData = JSON.stringify(dynamicdata);

        if (window.grecaptcha.getResponse() == "") {
            showErrorMessage("Ben robot değilim testi başarısız !");
            return false;
        }

        sendpost("submituser", window.userdata, "filesspin", (response) => {
            showSuccessMessage("Kullanıcı Kaydı Başarıyla Tamamlanmıştır", "", () => window.location.href = "/")
        }, (response) => {
            response.text().then(value => {
                showErrorMessage(value)

            })
        });




    }





    return <div className="mt-3">
        <div id="myscript"></div>
        <div className="row" >
            <div className="ms-2 me-2 p-0 col-md-3 col-12  border-bottom border-3 " > 1. Kişisel Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " >2. Mesleki Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 text-primary border-primary fw-bold" > 3. Diğer
            </div>
        </div>
        <div className="" >
            <div className="" >
                <div className="" >
                    <form action="submitapplication" className="mt-3" method="POST" onSubmit={onSubmit}>

                        <div>
                            {dynamicFields}



                            {globalData != null &&
                                (isEmpty(globalData.registerKVKK) == false || isEmpty(globalData.registerKVKKFile) == false) &&
                                <div className=" row"  >
                                    <div className="col-12 text-start">
                                        <input class="form-check-input" type="checkbox" id="registerKVKK" name="registerKVKK" />
                                        <label class="form-check-label" dangerouslySetInnerHTML={{ __html: globalData.registerKVKK }}></label>
                                        {globalData != null && isEmpty(globalData.registerKVKKFile) == false &&
                                            <a className="text-decoration-none" href='getgeneralfile?id=registerKVKKFile' download={globalData.registerKVKKFile}> {globalData.registerKVKKFile} </a>
                                        }
                                    </div>
                                </div>}


                            {globalData != null &&
                                (isEmpty(globalData.registerAgrement) == false || isEmpty(globalData.registerAgrementFile) == false) &&
                                < div className=" row mt-3 mb-3">
                                    <div className="col-12  text-start">
                                        <input class="form-check-input me-1" type="checkbox" id="registerAgrement" name="registerAgrement" />
                                        {isEmpty(globalData.registerAgrement) == false && <label className="form-check-label me-1" dangerouslySetInnerHTML={{ __html: globalData.registerAgrement }}></label>}
                                        {isEmpty(globalData.registerAgrementFile) == false &&

                                            <a className="text-decoration-none" href='getgeneralfile?id=registerAgrementFile' download={globalData.registerAgrementFile}> {globalData.registerAgrementFile} </a>

                                        }

                                    </div>
                                </div>}

                            <div className="mt-3 pt-2" class="g-recaptcha" data-sitekey={captcha}></div>

                            <div className="row mt-3 border-top pt-2" >
                                <div className="col-6 col-md-6 text-start">
                                    <button type="button" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 " onClick={() => { props.changeComponent(<Register2 changeComponent={props.changeComponent} />) }}>Geri Dön</button>
                                </div>
                                <div className="col-6 col-md-6 text-end">
                                    <span className="ms-1 spinner-border spinner-border-sm" role="status" aria-hidden="true" id="filesspin" hidden ></span>

                                    <button type="submit" name="actionSave" value="Save" className="btn btn-sm btn-primary mb-2 ">Tamamla</button>
                                </div>

                            </div>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
}



