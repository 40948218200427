import React, { Component } from 'react';
import { Link } from "react-router-dom";
import { useState, useEffect } from 'react';
import { showErrorMessage, useGlobalState } from '../GlobalState.js';



export default function RegisterUser(props) {
    const [overedvelet, setOveredevlet] = useState("");

    useEffect(() => {
        registeroveredevlet();

    }, [])

    const registeroveredevlet = () => {
        fetch("registeroveredevlet", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
            }
            else {
                response.text().then(async data => {

                    setOveredevlet(data.replaceAll('"', ''));

                });
            }
        });
    }

    if (overedvelet == "1") {
        return <div className="text-center mt-4 pt-4">
            <h5 className="mt-4 pt-4">Kayıt olmak için önce E-Devlet kapısı uzerinden giriş yapmanız gerekmektedir.</h5>
            <div class="row">
                <div class="col-md-12 col-lg-3"> </div>
                <div className="col-12 col-lg-6 text-center ">
                    <a className="btn btn-sm btn-danger text-decoration-none w-100 rounded-3  mt-3" href="register">
                        <img src={require('../Dashboard/edevletlogo.png').default} style={{ width: "28px", height: "28px" }} />
                        <b> E-Devlet şifresi ile Kayıt Ol</b>
                    </a>
                </div>
            </div>
        </div>
    }
    else if (overedvelet == "0"){
        return <div className="text-center mt-4 pt-4">
            <h5 className="mt-4 pt-4">Sisteme daha önce kaydedilmemiş T.C, Email ve Telefon numarası girmelisniz.</h5>
            <div class="row">
                <div class="col-md-12 col-lg-3"> </div>
                <div className="col-12 col-lg-6 text-center ">
                    <a className="btn btn-sm btn-primary text-decoration-none w-100 rounded-3  mt-3" href="register"> Devam Et</a>
                </div>
            </div>
        </div>

    }
    return "";
}



