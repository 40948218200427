import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react';

import UploadFile from "./UploadFile.js";
import { isEmpty, setVisible, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import UserWorkLoad from './UserWorkLoad.js';

import UserFilePanel from "./UserFilePanel.js";
import UserPersonelData from './UserPersonelData.js';
import UserMeslekData from './UserMeslekData.js';
import UserComData from './UserComData.js';
import UserTipData from './UserTypeData.js';
import UserDynamicData from './UserDynamicData.js';
import { ajaxFail } from '../GlobalState.js';

import * as UserPermConstants from '../User/UserPermConstants';

import UserEventsPanel from './UserEventsPanel.js';
import axios from "axios";


export default function UserDetails(props) {
    let location = useLocation();
    const [userdata, setUserData] = useState(props.rec != null ? props.rec : location.state != null ? location.state.rec : null);



    const [activePanel, setActivePanel] = useState(userdata != null ? <UserPersonelData userdata={userdata} /> : null);


    // sidebartags.push(<a className="d-block  p-2 m-2  rounded-3  text-decoration-none" href="javascript:void(0)" onClick={() => setData()}>Tum Mesajlar</a>);
    var sidebarPanel = [];
    sidebarPanel.push({ target: "/applicationform", state: { rec: userdata }, name: "İş Yuku", icon: "	fa fa-bar-chart" });


    const [role, setRole] = useGlobalState('role');
    var dynamicFields = "";
    const handleDynamicChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked.toString() : target.value;
        //dynamicdata[target.name] = { "value": value, "type": target.type };
    }

    if (userdata != null && userdata.dynamicData) {

        var ddata = JSON.parse(userdata.dynamicData);
        var keys = Object.keys(ddata);
        dynamicFields =
            keys.map(k =>
                <div className="col-6">
                    <label htmlFor={k} className="form-label">{k}</label><br />
                    {ddata[k].type != "select-one" ?
                        <input type={ddata[k].type} className={ddata[k].type != "checkbox" ? "form-control" : "form-check-input"}
                            id={k} placeholder={k} name={k} defaultValue={ddata[k].value} defaultChecked={ddata[k].value} onChange={handleDynamicChange} />
                        :
                        <select className="form-select form-select-sm" id={k} name={k} onChange={handleDynamicChange} >
                            {ddata[k].value != null ? ddata[k].value.split(",").map(rec =>
                                <option value={rec}>{rec}</option>) : ""}
                        </select>

                    }
                </div>

            )
    }

    useEffect(() => {
        if (userdata) {
            if (userdata.pic) {
                if (document.getElementById("selectedimage") != null) document.getElementById("selectedimage").src = "data:image/png;base64," + userdata.pic;
                if (document.getElementById("selectedimageright") != null) document.getElementById("selectedimageright").src = "data:image/png;base64," + userdata.pic;
            }

        }
        else
            fetchUserData();

    }, [])





    const onSubmit = (e) => {
        e.preventDefault();

        if (isEmpty(userdata.role)) {
            showErrorMessage("Kullanıcı Rolu Boş Olamaz.")
            return;
        }
        if (isEmpty(userdata.userType)) {
            showErrorMessage("Kullanıcı Tipi Boş Olamaz.")
            return;
        }
        

        fetch("updateuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(userdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response, true)
            }
            else {
                response.text().then(value => {
                })
                showSuccessMessage("Kullanıcı Bilgileri Güncellenmiştir. ");
            }
        });
    }

    const fetchUserData = () => {
        var param = "";
        if (userdata != null)
            param = "?user=" + userdata.userName;
        fetch("getuser" + param, {
            method: 'GET'
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(async data => {
                    setUserData(data);
                    setActivePanel(<UserPersonelData userdata={data} />)
                    if (document.getElementById("selectedimage") != null) document.getElementById("selectedimage").src = "data:image/png;base64," + data.pic;
                    if (document.getElementById("selectedimageright") != null) document.getElementById("selectedimageright").src = "data:image/png;base64," + data.pic;
                });
            }
        });
    }


    const hasNumber = (myString) => {
        return /\d/.test(myString);
    }
    const hasChar = (myString) => {
        return /[a-zA-Z]/g.test(myString);
    }


    const updatePassword = (event) => {
        var mevcut = document.getElementById("Password").value;
        var new1 = document.getElementById("newpass1").value;
        var new2 = document.getElementById("newpass2").value;
        var pass = JSON.stringify({ username: userdata.userName, mevcut: mevcut, new1: new1, new2: new2 });
        if ((mevcut == null || mevcut.length == 0) && role.indexOf(UserPermConstants.Sifre_Degistirme) == -1) {
            showErrorMessage("Mevcut Şifrenizi Giriniz");
            return;
        }
        if (new1 == null || new1.length < 8 || new2 == null || new2.length < 8) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (hasNumber(new1) == false || hasChar(new1) == false) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }

        if (new1 != new2) {
            showErrorMessage("Yeni girilen şifreler aynı değildir !");
            return;
        }

        fetch("updatepassword", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: pass
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                showSuccessMessage("Şifre Guncellenmiştir.")
            }
        });
    }


    const handleInputChange = (event) => {
        const target = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        userdata[target] = value;

        if (event.target.type === 'checkbox' && event.target.id === "karaliste")
            document.getElementById("karalistesure").disabled = !event.target.checked;

        if (event.target.type === 'checkbox' && event.target.id === "denetimYetkisi" && value == true) {
            showErrorMessage("Yetki Turleri Denetim Yetkileri Panelinden Belirlenmelidir")
        }

    }



    var sifrepanel = "";

    if (userdata != null) {
        sifrepanel = <div >
            <div className="row">
                <h5>Şifre</h5>
                <div className="col-3">

                </div>
                <div className="col-2">
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="Password" className="form-label">Mevcut Şifre</label>
                </div>
                <div className="col-8">
                    <input type="password" className="form-control" id="Password" placeholder="Mevcut Şifre" name="password" onChange={handleInputChange} defaultValue="" />
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="newpass1" className="form-label">Yeni Şifre</label>
                </div>
                <div className="col-8">
                    <input type="password" className="form-control" id="newpass1" placeholder="Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr" name="newpass1" onChange={handleInputChange} defaultValue="" />
                </div>
            </div>
            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="newpass2" className="form-label">Yeni Şifre (Tekrar)</label>
                </div>
                <div className="col-8">
                    <input type="password" className="form-control" id="newpass2" placeholder="Yeni Şifre (Tekrar)" name="newpass2" onChange={handleInputChange} defaultValue="" />
                </div>

            </div><button type="button" onClick={updatePassword} className="btn btn-primary btn-sm mt-4">Güncelle</button>
        </div >

    }



    var isyuku = "";
    if (role.indexOf(UserPermConstants.Is_Yuku) >= 0 && userdata != null) {
        isyuku = <button type="button" className="btn btn-link btn-sm text-decoration-none p-0" data-bs-toggle="modal" data-bs-target="#myModal">
            İş Yuku
        </button>

    }

    const deletefile = (e) => {
        var formData = new FormData();
        formData.append("caption", userdata.userName);
        formData.append("typeID", 0);
        formData.append("appid", 0);
        setVisible("myspiner", true);
        axios.post(
            "submituserpic",
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data"
                },
            }
        )
            .then(async res => {
                setVisible("myspiner", false);
                setActivePanel(<UserPersonelData userdata={userdata} />)
            
            })
            .catch(err => {
                setVisible("myspiner", false);
                showErrorMessage(err)
            })
    }




    if (userdata == null) {
        return <div>lütfen bekleyin..</div>
    }
    const changeComponent = props.changeComponent;

    return (

        <div className="p-4 pt-3" style={{ "background-color": "#ebebeb", "min-height": "100vh" }} key={userdata}>
            <div className="row mb-1">
                <div className="col-12 text-end">
                    <button className="btn btn-sm btn-primary rounded me-2" onClick={() => { fetchUserData() }}> <i className="fa fa-refresh pe-1"></i> Yenile</button>

                    <button className="btn btn-sm btn-primary rounded me-3" onClick={() => {
                        if (props.backButton)
                            changeComponent(props.backButton)
                        else {
                            window.location.href = "/";
                        }
                    }}> <i className="fa fa-arrow-circle-left pe-1"></i> Geri Don</button>
                </div>
            </div>
            <div className="row">
                <div className="col-md-3 col-12">
                    <div className="bg-white rounded-3 p-2">
                        <div className='row'>
                            <div className="col-12 col-md-4">
                                <img className="rounded-circle" style={{ width: "48px", height: "48px" }} src={"/getuserpic?user=" + userdata.userName + "&_=" + Date.now()} />
                                <div className="d-flex">
                                   
                                    <UploadFile username={userdata.userName} filelabel={"Fotoğrafı Değiştir"} target={"submituserpic"}
                                        setSumbitResult={(g) => setActivePanel(<UserPersonelData userdata={userdata} />)} />
                                    <button className="btn btn-link m-0 p-0 ps-2" onClick={deletefile}><i class="fa fa-trash-o"></i></button>
                                    <span className="spinner-border spinner-border-sm ms-3" id="myspiner" hidden>   </span>
                                </div>
                            </div>

                            <div className="col-12 col-md-8">
                                <div className="fw-bold text-black"> {userdata.userName}</div>
                                <div className="fw-bold text-muted"> {userdata.name}</div>
                                <div className="fw-bold text-muted"> {userdata.family}</div>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserPersonelData userdata={userdata} />) }}>
                                    Kişisel Bilgiler
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserMeslekData userdata={userdata} />) }}>
                                    Mesleki Bilgiler
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(sifrepanel) }}>
                                    Şifre Değiştir
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(< UserComData userdata={userdata} />) }}>
                                    İletişim Bilgileri
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserTipData userdata={userdata} />) }}>
                                    Kullanıcı Tipi ve Yetkileri
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserFilePanel userdata={userdata} />) }}>
                                    Dosyalar
                                </button>
                            </div>
                        </div>

                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserEventsPanel userdata={userdata} />) }}>
                                    Geçmiş Aktiviteler
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserWorkLoad userdata={userdata} />) }}>
                                    İş Yuku
                                </button>
                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className="col-12 ps-4">
                                <button type="button" className="btn fw-bold btn-link btn-sm text-muted text-decoration-none p-0" onClick={() => { setActivePanel(<UserDynamicData userdata={userdata} changeComponent={props.changeComponent} />) }}>
                                    Diğer
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-9 col-12 rounded-3">

                    <div className="bg-white rounded-3 p-3 me-3">
                        <form className="mt-1" method="POST" onSubmit={onSubmit} key={userdata}>
                            <fieldset disabled={role.indexOf(UserPermConstants.Kullanici_Bilgilerini_Editleme_Roller_Denetim_Yetkileri_Haric) == -1}>
                                {activePanel}
                            </fieldset>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}



