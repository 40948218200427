import React, { useState, useEffect } from 'react';
import { ajaxFail, sendget, showSuccessMessage, useGlobalState, showErrorMessage, sendpost, getHtmlTag, isEmpty } from '../GlobalState.js';

import CommunicationSetting from './CommunicationSetting.js';
import * as UserPermConstants from '../User/UserPermConstants';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import axios from "axios";

export default function AllEvents(props) {
    var [events, setEvents] = useState({ items:[]});
    var [currentPage, setCurrentPage] = useState(1);
    const [role, setRole] = useGlobalState('role');
    const [userName, setUserName] = useGlobalState('userName');

    useEffect(() => {

    }, [])

    const Pagination = ( totalCount, pageSize, currentPage, onPageChange ) => {
        const totalPages = Math.ceil(totalCount / pageSize);
        if (totalPages <= 1) return null; // No pagination needed for a single page
        const handlePageChange = (page) => {
            if (page > 0 && page <= totalPages) {
                onPageChange(page);
            }
        };
        const pageNumbers = Array.from({ length: totalPages }, (_, index) => index + 1);
        return (
            <div
                className="overflow-auto w-100 mainpagescroll"
                style={{
                    whiteSpace: "nowrap",
                    maxWidth: "100%",
                }}
            >
            <nav aria-label="Page navigation">
                <ul className="pagination mb-0 pb-0">
                    {pageNumbers.map((page) => (
                        <li
                            key={page}
                            className={`page-item  ${page === currentPage ? "active" : ""}`}>
                            <button
                                className="page-link "
                                onClick={() => handlePageChange(page)}
                            >
                                {page}
                            </button>
                        </li>
                    ))}
                </ul>
                </nav>
            </div>
        );
    };

    const sendRequest = (cp) => {
        var keywords = getHtmlTag("keyword", "value");
        var start = getHtmlTag("startDate", "value");
        var end = getHtmlTag("endDate", "value");
        if (isEmpty(keywords) && isEmpty(start) && isEmpty(end)  ) {
            showErrorMessage("Anahtar kelime boş olamaz")
            return;
        }
        var keywords = {
            keywords: keywords,
            startDate: start,
            endDate:end
        }
        sendpost("geteventlist?pageNumber=" + cp, JSON.stringify(keywords), "loadspin1", (response) => {
            response.json().then(async data => {
                setEvents(data);
                setCurrentPage(cp)
            });
        })
    }

    return (
        <div className="rounded-3">
            <h5>Geçmiş Aktiviteler</h5>

            <div className="row">
                <div className="col-3">
                    Anahtar Kelimeler <input type="text" className="form-control form-control-sm" placeholder="" id="keyword" />
                </div>
                <div className="col-2">
                    Başlangıç  <input type="date" className="form-control form-control-sm" placeholder="Anahtar Kelimeler" id="startDate" />
                </div>
                <div className="col-2">
                    Bitiş <input type="date" className="form-control form-control-sm" placeholder="Anahtar Kelimeler" id="endDate" />
                </div>
                <div className="col-3"><br />
                    <button className="btn btn-sm btn-primary me-1" onClick={()=>sendRequest(1)}>Göster</button>
                    <button className="btn btn-sm btn-primary me-1" onClick={() => setEvents({ items: [] })}>Temizle</button>
                    <span class="spinner-border  spinner-border-sm" hidden id="loadspin1" ></span>
                </div>
            </div>
            <div className="row mt-1">
                <div className="col-12" id="pagination">
                    {Pagination(events.totalCount, 200, currentPage, (p) => {
                        setCurrentPage(p)
                        sendRequest(p);
                    })}
                </div>
            </div>

            <div className="border rounded-3 mt-2" key={events}>

                {
                    events.items.map((e, idx) =>
                        <div className="row border-bottom" style={{ backgroundColor: (idx % 2 == 0) ? "#efffff" : "" }}>
                            <div className="col-8">
                                {e.comment}
                            </div>
                            <div className="col-2">
                                {e.eventDate}
                            </div>
                            <div className="col-2">
                                {e.userName}
                            </div>
                        </div>
                    )
                }
            </div>
        </div>
    );


}


