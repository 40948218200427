import React, { useState, useEffect } from 'react';
import * as UserPermConstants from '../User/UserPermConstants';
import Application from "./Application.js"
import { useGlobalState, logout, ajaxFail, removeTurkcLow, showErrorMessage } from '../GlobalState.js';
import $ from 'jquery';


export default function AllApplicationList(props) {


    const [role, setRole] = useGlobalState('role');
    const [userName, setuserName] = useGlobalState('userName');
    var changeComponent = props.changeComponent;

    const [showremoved, setShowremoved] = useState(false);
    const [applist, setApplist] = useState(null);
    const [appGroups, setAppGroups] = useState([]);
    const [devamstat, setDevamstat] = useState(null);
    const [filter, setFilter] = useState(null);

    const [surecList, setSurecList] = useState([]);
    const [mysablonlist, setMysablonlist] = useState(null);
    const [colnames, setColnames] = useState(["id", "basvuruTarihi", "basvuruTipi", "basvuruDurumu", "ada", "mahalle", "mevki", "basvuruYapan"]);

    var sortField = null;



    useEffect(() => {
        getSablon();
        fetchSurecList();
    }, [])

    const myCompare = (a, b) => {
        if (a[sortField] < b[sortField]) {
            return -1;
        }
        if (a[sortField] > b[sortField]) {
            return 1;
        }
        return 0;
    }
    const myCompareRev = (a, b) => {
        if (a[sortField] < b[sortField]) {
            return 1;
        }
        if (a[sortField] > b[sortField]) {
            return -1;
        }
        return 0;
    }


    const myCompareBasvuruTipi = (a, b) => {
        var s1 = a[sortField];
        var s2 = b[sortField];
        if (s1 == null) return -1;
        if (s2 == null) return 1;

        var sa = s1.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");
        var sb = s2.replaceAll('"name":', "").replaceAll(']', "").replaceAll('[', "").replaceAll('{', "").replaceAll('}', "").replaceAll('"', "");

        if (sa < sb) {
            return -1;
        }
        if (sa > sb) {
            return 1;
        }
        return 0;
    }


    const sortList = (fildname) => {
        sortField = fildname;
        var temp = [...applist]; //clone the array to update the component on setApplist
        temp.sort(myCompare);
        setApplist(temp);
    }


    const getAppGroupList = () => {

        fetch("getappgrouplist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    value.unshift({ name: "Tümü" })
                    setAppGroups(value);
                })
            }
        });
    }


    const getSablon = () => {
        var refreshButton = document.getElementById("refreshButton");
        if (refreshButton != null) {
            refreshButton.classList.remove("fa", "fa-refresh")
            refreshButton.classList.add("spinner-grow", "spinner-grow-sm")
        }

        fetch("getmysablon", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }

            else {
                response.json().then(async list => {
                    var list = list.filter(r => r.forListView == true);
                    if (list != null && list.length > 0) {
                        var data = list[0];
                        if (window.localStorage.getItem('sablon') != null && list[window.localStorage.getItem('sablon')] != null)
                            data = list[window.localStorage.getItem('sablon')];
                        if (data.filter != null && data.filter.length > 0)
                            setFilter(data.filter);
                        if (data.colNames != null && data.colNames.length > 0) {
                            var d = data.colNames.replaceAll('"', "");
                            if (d.length > 0)
                                setColnames(d.split(","));
                        }
                    }
                    setMysablonlist(list);
                });

                getApplicationList();
            }
        });
    }



    const appsauthorlist = (applist) => {
        var ids = applist.map(x => x.id).join();
        fetch("appsauthorlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    applist.forEach((k) => { k.müellif = "" });
                    data.forEach((k) => { applist.find(x => x.id == k.appID).müellif += k.userName + "(" + k.muellifType + ")  " });
                    setApplist(applist);
                });
            }
        });
    }

    const getcontrollerlist = (applist) => {
        var ids = applist.map(x => x.id).join();
        fetch("getcontrollerlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(ids)

        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    try {
                        data = JSON.parse(data)
                        data.forEach((k) => { applist.find(x => x.id == k.appID).kontrolor = k.userName });
                        appsauthorlist(applist)
                    } catch (error) {
                        setApplist([]);
                    }
                });
            }
        });
    }


    const getApplicationList = () => {
        fetch("myallapplicationlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            var refreshButton = document.getElementById("refreshButton");
            if (refreshButton != null && refreshButton.classList.contains("fa-refresh") == false) {
                refreshButton.classList.remove("spinner-grow", "spinner-grow-sm")
                refreshButton.classList.add("fa", "fa-refresh")
            }

            if (!response.ok) {
                ajaxFail(response);
            }
            else {

                response.json().then(async data => {
                    getcontrollerlist(data);
                });
            }
        });
    }


    const filterTable = (event) => {
        const target = event.target;
        const value = target.value.toLowerCase();
        $("#myTable tr").filter(function () {
            var keywords = value.split(" ");
            var t1 = removeTurkcLow($(this).text())
            for (let index = 0; index < keywords.length; ++index) {
                if (t1.indexOf(removeTurkcLow(keywords[index])) == -1)
                    return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });
    }


    const passFilter = (rec, filter) => {
        if (filter == null || filter.length == 0) return true;
        var result = false;
        var conds = filter.split(",");
        if (conds == null) return true;
        for (var i = 0; i < conds.length; i++) {
            var currentCondition = conds[i].trim();
            var and = currentCondition.includes("AND") || conds.length == 1;
            and = and || (i == 0 && conds.length > 1 && conds[1].trim().includes("AND"));
            currentCondition = currentCondition.replaceAll("AND", "");
            currentCondition = currentCondition.replaceAll("OR", "");
            if (currentCondition.includes('>')) {
                var c = currentCondition.split(">");
                var colname = c[0].trim();
                var value = c[1].trim().split(";");
                if (rec[c[0]] != null) {
                    if (!value.some(v => parseInt(rec[colname]) > parseInt(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname]) > parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('<')) {
                var c = currentCondition.split("<");
                var colname = c[0].trim();
                var value = c[1].trim().split(";");
                if (rec[colname] != null) {
                    if (!value.some(v => parseInt(rec[colname]) < parseInt(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname].value) < parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }

            }
            if (currentCondition.includes('=')) {
                var c = currentCondition.split("=");
                var colname = c[0].trim();
                var value = c[1].trim().split(";");
                if (rec[colname] != null) {
                    if (!value.some(v => parseInt(rec[colname]) == parseInt(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname].value) == parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('!=')) {
                var c = currentCondition.split("!=");
                var colname = c[0].trim();

                var value = c[1].trim().split(";");
                if (rec[colname] != null) {
                    if (!value.some(v => parseInt(rec[colname]) != parseInt(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null || !value.some(v => parseInt(dynamicSoru[colname].value) == parseInt(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('ISNOTNULL')) {
                var c = currentCondition.split("ISNOTNULL");
                var colname = c[0].trim();


                if (rec[colname] == null) {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }
            if (currentCondition.includes('ISNULL')) {
                var c = currentCondition.split("ISNULL");
                var colname = c[0].trim();
                if (rec[colname] != null) {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] != null) {
                            if (and == true) return false;
                        }

                        else result = true;
                    }
                }
            }
            if (currentCondition.includes('LIKE')) {
                var c = currentCondition.split("LIKE");
                var colname = c[0].trim();
                var value = c[1].trim().split(";");

                if (rec[colname] != null) {
                    if (!value.some(v => rec[colname].toString().includes(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null || !value.some(v => dynamicSoru[colname].value.toString().includes(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }
                    else
                        if (and == true) return false;
                }
            }

            if (currentCondition.includes('NOTLIKE')) {
                var c = currentCondition.split("NOTLIKE");
                var colname = c[0].trim();
                var value = c[1].trim().split(";");
                if (rec[colname] != null) {
                    if (value.some(v => rec[colname].toString().includes(v))) {
                        if (and == true) return false;
                    }
                    else result = true;
                }
                else {
                    if (rec.appData != null && rec.appData.length > 0) {
                        var dynamicSoru = JSON.parse(rec.appData);
                        if (dynamicSoru[colname] == null || value.some(v => dynamicSoru[colname].value.toString().includes(v))) {
                            if (and == true) return false;
                        }
                        else result = true;
                    }

                }
            }


        }

        return result;
    }


    const filterColumn = (event) => {
        const target = event.target;

        var cl = colnames;
        $("#myTable tr").filter(function () {
            for (var c = 0; c < cl.length; c++) {
                if (document.getElementById(cl[c]).value != null && document.getElementById(cl[c]).value.length > 0)
                    if (removeTurkcLow($(this).children().eq(c).text()).indexOf(removeTurkcLow(document.getElementById(cl[c]).value)) == -1)
                        return $(this).toggle(false);
            }
            return $(this).toggle(true);
        });
    }


    const handleGroupChange = () => {
        var sab = document.getElementById("groupSelect").value;
        var data = appGroups[sab];
        setDevamstat(data);
    }

    const handleSablonChange = () => {
        var sab = document.getElementById("sablonSelect").value;
        window.localStorage.setItem('sablon', sab);
        var data = mysablonlist[sab];
        if (data.filter != null)
            setFilter(data.filter);
        if (data.colNames != null && data.colNames.length > 0) {
            var d = data.colNames.replaceAll('"', "");
            if (d.length > 0) {
                var temp = applist;
                if (data.sort != null && data.sort.length > 0) {
                    var sf = data.sort.split(",");
                    if (sf.length > 0) {
                        for (var i = 0; i < sf.length; i++) {
                            if (sf[i][0] == '-') {
                                sortField = sf[i].substring(1);
                                temp.sort(myCompareRev);
                            }
                            else {
                                sortField = sf[i];
                                temp.sort(myCompare);
                            }
                        }
                    }

                }
                setApplist(temp);
                setColnames(d.split(","));
            }
        }
    }

    const fetchSurecList = () => {

        fetch("sureclist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);

            }
            else {
                response.json().then(value => {
                    setSurecList(value);
                    getAppGroupList();
                })
            }
        });
    }



    const setAppRemovedState = (app, isremoved) => {
        app.removed = isremoved;
        fetch("saveapplication", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(app)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                getApplicationList();
            }
        });
    }



    const deleteApp = (app) => {
        var comment = window.prompt("Lütfen Silme Nedenini Açıklayınız")
        if (comment == null || comment.length == 0) {
            showErrorMessage("Açıklama Boş Olamaz", "Lütfen Silme Nedenini Yazınız");
            return;
        }


       if (app.removed != true) {
            setAppRemovedState(app, true);
            return;
        }
        fetch("removeapp", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(app.id)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(async data => {
                    setApplist(data);
                });
            }
        });
    }


    const getDurumName = (i) => {
        var r = surecList.find(element => element.id == i);
        if (r == null) return i;
        return r.name;
    }




    const renderToList = (tabledata) => {
        if (tabledata == null)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>


        if (tabledata.length == 0)
            return <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center text-danger" >Kayıt Yok</div>


        var mytable =
            <div className="mt-1">
                <div className="mt-1 p-2 row" style={{ "background-color": "#fff8dd" }}>
                    <div class="col-md-2 pt-4">
                        {role != null && role.indexOf(UserPermConstants.Basvuru_Silme) > -1 && <div class="form-check form-switch ">
                            <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefault" defaultChecked={false} onClick={(value) => setShowremoved(value.target.checked)} />
                            <label class="form-check-label" for="flexSwitchCheckDefault" >Silinenler</label>
                        </div>}
                    </div>
                    <div className="col-12 col-md-3">
                        Ekran
                        {mysablonlist != null ? <select className="form-select form-select-sm" id="sablonSelect" name="sablonSelect" onChange={handleSablonChange} defaultValue={window.localStorage.getItem('sablon')}>
                            {mysablonlist.map((s, idx) => <option value={idx} > {s.name}</option>)}
                        </select> : ""}
                    </div>
                    <div className="col-12 col-md-3">
                        Gruplar
                        {appGroups != null ? <select className="form-select form-select-sm" id="groupSelect" name="groupSelect" onChange={handleGroupChange}>
                            {appGroups.map((s, idx) => <option value={idx} > {s.name}</option>)}
                        </select> : ""}
                    </div>
                    <div className="col-12 col-md-3">
                        Arama
                        <input className="form-control form-control-sm" id="myInput" type="text" placeholder="" onKeyUp={filterTable} />

                    </div>
                    <div className="col-12 col-md-1 text-end pt-2">
                        <a onClick={getSablon} href="#" className="text-primary"> <i className="fa fa-refresh" id="refreshButton"></i></a>
                    </div>

                </div>


                <div className="table-responsive">
                    <table className='table mt-3 table-vdstriped' aria-labelledby="tabelLabel">
                        <thead>
                            <tr>
                                {colnames.map(c =>
                                    <th> <div><button className="btn btn-link btn-sm text-decoration-none" onClick={() => sortList(c)}> {c.toUpperCase() == "ADA" ? "ADA/PARSEL" : c.toUpperCase()}</button></div>
                                        <div>
                                            <input type="text" className="form-control form-control-sm" id={c} placeholder="" name={c} onChange={filterColumn} />
                                        </div></th>
                                )}
                            </tr>
                        </thead>
                        <tbody id="myTable">
                            {tabledata.map(apprec =>
                                (showremoved == true ? apprec.removed == true : apprec.removed != true) &&
                                passFilter(apprec, filter) == true && (devamstat == null || devamstat.appState == undefined || devamstat.appState.split(",").includes(apprec.basvuruDurumu + "")) &&

                                <tr key={apprec.id} className="p-1">
                                    {colnames.map(c => {
                                        if (apprec[c] != null)
                                            if (c == "basvuruDurumu") return <td name={c}>{getDurumName(apprec[c])}</td>
                                            else return <td name={c}>{apprec[c]}</td>
                                        try {
                                            var appDData = JSON.parse(apprec.appData);
                                            if (appDData != null && appDData[c] != null) {
                                                if (appDData[c].type != null && appDData[c].type == "checkbox") {
                                                    return <td name={c}><input type="checkbox" className="form-check-input p-0" checked={appDData[c].value} /></td>
                                                }
                                                return <td name={c}>{appDData[c].value}</td>
                                            }
                                        }
                                        catch (ex) {
                                            return <td name={c}>{ex.toString()}</td>
                                        }

                                        return <td></td>
                                    })}
                                    <td>
                                       { (role != null && role.indexOf(UserPermConstants.Basvuru_Silme) > -1)&&  <div style={{ "overflow": "hidden", "white-space": "nowrap" }}>
                                                {apprec.removed != true && <a href="#" onClick={() => { changeComponent(<Application key={apprec.id} appData={apprec} changeComponent={changeComponent} backButton={<AllApplicationList changeComponent={props.changeComponent} />}/>) }} className=" text-decoration-none "><i className="fa fa-edit align-middle" style={{ "font-size": "24px" }}></i></a>}
                                                {apprec.removed == true && <a href="/#" onClick={() => { setAppRemovedState(apprec, false) }} className="text-decoration-none align-middle" ><i className="fa fa-refresh align-middle" style={{ "font-size": "20px" }}></i></a>}
                                                 <a href="/#" onClick={() => { deleteApp(apprec) }} className="text-decoration-none material-icons align-middle" >delete</a>

                                        </div>}
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>
            </div>
        return (mytable);
    }

    var  tabledata = renderToList(applist);
   

    return <div  >
        <div className="  align-items-center justify-content-center p-1" >
            {tabledata}
        </div>
    </div>

}





