import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import * as UserPermConstants from '../User/UserPermConstants';
import { showErrorMessage, useGlobalState } from '../GlobalState.js';

export default function UserPersonelData(props) {
    const [userdata, setUserData] = useState(props.userdata);
    const [role, setRole] = useGlobalState('role');
   
    const handleInputChange = (event) => {
        const target = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        userdata[target] = value;

        if (event.target.type === 'checkbox' && event.target.id === "karaliste")
            document.getElementById("karalistesure").disabled = !event.target.checked;

        if (event.target.type === 'checkbox' && event.target.id === "denetimYetkisi" && value == true) {
            showErrorMessage("Yetki Turleri Denetim Yetkileri Panelinden Belirlenmelidir")
        }

    }





    return <div key={userdata}>
            <div className="row">
                
                <div className="col-3">
<h5>Kişisel Bilgileri</h5>
                </div>
                <div className="col-2">
                <img className="rounded-circle" style={{ width: "48px", height: "48px" }} src={"/getuserpic?user=" + userdata.userName + "&_=" + Date.now()} />
                       
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="UserName" className="form-label">Kullanıcı Adı</label>
                </div>
                <div className="col-8">
                    <input readOnly type="text" className="form-control col-9" id="UserName" placeholder="UserName" name="userName" onChange={handleInputChange} defaultValue={userdata.userName} />
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="UserName" className="form-label">T.C</label>
                </div>
            <div className="col-8">
                <input type="text" maxLength="11" className="form-control col-9" id="tc" placeholder="T.C" name="tc" onChange={handleInputChange} defaultValue={userdata.tc} disabled={userdata.edveletConfirmed == true && role.indexOf(UserPermConstants.Sabitlenen_Profil_Degistirme)==-1  } />
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="Name" className="form-label">Adı</label>
                </div>
                <div className="col-8">
                <input type="text" className="form-control" id="Name" placeholder="Name" name="name" onChange={handleInputChange} defaultValue={userdata.name} disabled={userdata.edveletConfirmed == true && role.indexOf(UserPermConstants.Sabitlenen_Profil_Degistirme) == -1} />

                </div>
            </div>


            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="Family" className="form-label">Soyadı</label>
                </div>
                <div className="col-8">
                <input type="text" className="form-control" id="Family" placeholder="Family" name="family" onChange={handleInputChange} defaultValue={userdata.family} disabled={userdata.edveletConfirmed == true && role.indexOf(UserPermConstants.Sabitlenen_Profil_Degistirme) == -1} />
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="Family" className="form-label">Doğum Tarihi</label>
                </div>
                <div className="col-8">
                    <input type="date" className="form-control" id="birthdate" placeholder="Doğum Tarihi" name="birthdate" onChange={handleInputChange} defaultValue={userdata.birthdate} />
                </div>
            </div>

            <div className="row pt-4">
                <div className="col-3">
                    <label htmlFor="RegDate" className="form-label">Üyelik Tarihi</label>
                </div>
                <div className="col-8">
                    <input readOnly type="text" className="form-control" id="RegDate" placeholder="RegDate" name="regDate" defaultValue={userdata.regDate} />
                </div>
            </div>

            <button type="submit" className="btn btn-primary btn-sm mt-4">Güncelle</button>
        </div>


    }




