import React, { useState, useEffect, createRef } from 'react';
import Multiselect from 'multiselect-react-dropdown';
import { isEmpty, showErrorMessage, showSuccessMessage, useGlobalState } from '../GlobalState.js';
import { ajaxFail, removeTurkcLow } from '../GlobalState.js';
import $ from 'jquery';
import FileSettingList from './FileSettingList.js';
import BasvuruAlanlariSetting from './BasvuruAlanlariSetting.js';


export default function BasvuruAlaniDetail(props) {
    var postdata = {};
    const rec = props.appRec;
    var basvuruTurleri = props.basvuruTurleri.filter(x=>x.isadimi!=true)
    const typeOptions = props.denetims;

    const roles=props.roles;

    const multiselectRefNew = React.createRef();
    const multiselectRefNewRole = React.createRef();

    useEffect(() => {

    }, [])

    const submitUpdateRecord = (rec) => {

        fetch("updateAppField", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Güncelleme Başarılı")
                
            }
        });
    }



    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.join();
        postdata["basvuruTipi"] = clist;
    }
    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.join();
        postdata["basvuruTipi"] = clist;
    }

    const Add = () => {


        fetch("addAppField", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                showSuccessMessage("Kayıt Başarılı", "", () => window.changeComponent(<BasvuruAlanlariSetting />));
            }
        });

    }
    const handleInputChange = (event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        if (target.checkValidity() == false) {
            showErrorMessage("Sadece harf veya rakam girilebilir");

            target.value = "";
            return;
        }
        postdata[target.name.trim()] = value;
    }


    const renderTable = (tabledata) => {
        var mytable =
            <div className="table-responsive mainpagescroll" style={{ "height": "85vh" }}>
                <table className='table table-vdstriped  table-sm' aria-labelledby="tabelLabel">

                    <tbody id="myTable">
                        {tabledata.map(apprec =>
                            <tr key={apprec.id}>
                                <td hidden>
                                    {apprec.basvuruTipi} {apprec.name}
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                <td>
                                </td>
                                {/* <td>{apprec.crerateTime}</td>*/}

                                <td>
                                    <button onClick={() => submitUpdateRecord(apprec)} className="btn btn-link btn-sm text-decoration-none m-0 p-0">Güncelle</button>&nbsp;
                                </td>
                            </tr>
                        )}
                        <tr className="bg-white">
                            <td> </td>
                            <td >

                                <Multiselect className="p-0 form-control-sm bg-white"
                                    options={basvuruTurleri.map(b => b.name)}
                                    emptyRecordMsg="Başka Yetki Yok"
                                    ref={multiselectRefNew}
                                    isObject={false}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    placeholder="Başvuru Tipleri"
                                    selectedValues={[]}
                                /></td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" id="name" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_ ]+" placeholder="Soru" name="name" onChange={handleInputChange} defaultValue="" />
                            </td>
                            <td>
                                <select className="form-select form-select-sm" id="type" name="type" placeholder="Soru Türü" defaultValue="" onChange={handleInputChange} >
                                    <option value="text">Yazı</option>

                                    <option value="select">Çoktan Seçmeli</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="number">Sayı</option>
                                    <option value="select multiple">Çoklu Seçim</option>
                                    <option value="date">Tarih</option>
                                    <option value="textarea">Buyuk Yazı</option>
                                </select>

                            </td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Varsayılan Değer" name="initValue" id="initValue" defaultValue="" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Sıra" name="sira" id="sira" defaultValue="" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" id="reqired" name="reqired" onChange={handleInputChange} />
                            </td>
                            <td>
                                <input type="checkbox" className="form-check-input" id="active" name="active" onChange={handleInputChange} />
                            </td>
                            <td> <input type="checkbox" className="form-check-input" id="printable" name="printable" onChange={handleInputChange} /></td>
                            <td> <input type="checkbox" className="form-check-input" id="gizli" name="gizli" onChange={handleInputChange} /></td>

                            <td>
                                <Multiselect className=" p-0 form-control form-control-sm "
                                    options={roles}
                                    placeholder="Rol"
                                    emptyRecordMsg="Başka Kayıt Yok"
                                    displayValue="name"
                                    isObject={true}
                                    ref={multiselectRefNewRole}
                                    selectedValues={[]}
                                    onSelect={(selectedList, removedItem) => { postdata.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                                    onRemove={(selectedList, removedItem) => { postdata.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                                />
                            </td>
                            {/*  <td></td>*/}

                            <td>  <button onClick={Add} className="btn btn-sm btn-link text-decoration-none m-0 p-0">Ekle</button></td>
                        </tr>
                        <tr className="bg-white">
                            <td> </td>
                            <td >

                                <Multiselect className="p-0 form-control-sm bg-white"
                                    options={basvuruTurleri.map(b => b.name)}
                                    emptyRecordMsg="Başka Yetki Yok"
                                    ref={multiselectRefNew}
                                    isObject={false}
                                    onSelect={onSelect}
                                    onRemove={onRemove}
                                    placeholder="Başvuru Tipleri"
                                    selectedValues={[]}
                                /></td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" id="name" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_ ]+" placeholder="Soru" name="name" onChange={handleInputChange} defaultValue="" />
                            </td>
                            <td>
                                <select className="form-select form-select-sm" id="type" name="type" placeholder="Soru Türü" defaultValue="" onChange={handleInputChange} >
                                    <option value="text">Yazı</option>

                                    <option value="select">Çoktan Seçmeli</option>
                                    <option value="checkbox">Checkbox</option>
                                    <option value="number">Sayı</option>
                                    <option value="select multiple">Çoklu Seçim</option>
                                    <option value="date">Tarih</option>
                                    <option value="textarea">Buyuk Yazı</option>
                                </select>

                            </td>
                            <td>
                                <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="Varsayılan Değer" name="initValue" id="initValue" defaultValue="" onChange={handleInputChange} />
                            </td>


                        </tr>
                    </tbody>
                </table>
            </div>
        return (mytable);
    }





    
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value.trim();
        const name = target.name;
        rec[name] = value;
    }








    var listTable = renderTable([]);


    var mus = document.getElementById("KonMultiSelect");
    if (mus != null) {
        if (mus.disabled == true) {
            if (!mus.classList.contains('bg-pasif')) {
                mus.classList.add("bg-pasif");
            }
        }
        else {
            mus.classList.remove("bg-pasif");
            mus.classList.add("bg-white");
        }
    }



    return (
        <div className="p-2 rounded-3" style={{ "background-color": "#EDEDF7", "min-height": "100vh" }}>
            <div className="row " >
                <div className="col-12 col-md-6 " >

                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#4b5675" }}> {rec.userName} </span>
                    <span className="me-3" style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}>{rec.crerateTime} </span>
                    <span style={{ "font-size": "14px", "font-weight": "600", "color": "#99a1b7" }}> Id: {rec.id} </span>

                </div>
                <div className="col-12 col-md-6 text-end  pt-1" >
                    <button onClick={() => {
                        if (rec.id != null)
                            submitUpdateRecord(rec)
                        else
                            Add(rec)
                    }
                    } className="btn btn-primary btn-sm text-decoration-none me-2" > Kaydet </button>
                    <button onClick={() => window.changeComponent(<BasvuruAlanlariSetting />)} className="btn btn-secondary btn-sm text-decoration-none">Vazgeç</button >
                </div>
            </div>

            <div className="row mt-1 p-3" >
                <div className="col-12 col-md-6" >
                    <div className=" bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom" >
                            Genel Özellikler

                        </div>
                        <div className="p-4" >
                            Soru Adı <span className="text-danger" >* </span>

                            <input type="text" className="form-control form-control-sm" pattern="[a-zA-Z0-9ÜüŞşÇçÖöĞğİı\(\)_\- ]+" placeholder="Soru Adı" name="name" defaultValue={rec.name} onChange={(e) => updateRecord(rec, e)} />

                        </div>

                        <div className="p-4" >
                            Başvuru Tipi  <span className="text-danger" >* </span>
                            <Multiselect className="p-0"
                                options={basvuruTurleri.map(b => b.name)}
                                emptyRecordMsg="Başka Başvuru Tipi Yok"

                                isObject={false}
                                onSelect={(selectedList, selectedItem) => { rec.basvuruTipi = selectedList.join() }}
                                onRemove={(selectedList, selectedItem) => { rec.basvuruTipi = selectedList.join() }}
                                placeholder=""
                                selectedValues={rec.basvuruTipi != null ? rec.basvuruTipi.split(',') : []}
                            />
                            <div class="textvmuted">
                                Sorunun hangi başvurularda yer alacağını seçebilirsiniz.
                            </div>
                        </div>

                        < div className="p-4" >

                            Soru Tipi < span className="text-danger" >* </span>

                            <select style={{ "background-color": "transparent" }} className="form-select form-select-sm" name="type" placeholder="Soru Türü" defaultValue={rec.type} onChange={(e) => updateRecord(rec, e)} >
                                <option value="text">Yazı</option>

                                <option value="select">Çoktan Seçmeli</option>
                                <option value="checkbox">Checkbox</option>
                                <option value="number">Sayı</option>
                                <option value="select multiple">Çoklu Seçim</option>
                                <option value="date">Tarih</option>
                                <option value="textarea">Buyuk Yazı</option>
                            </select>
                        </div>

                        < div className="p-4" >
                            Varsayılan Değer
                            <input type="text" style={{ "background-color": "transparent" }} className="form-control form-control-sm" placeholder="" name="initValue" defaultValue={rec.initValue} onChange={(e) => updateRecord(rec, e)} />
                            <div className="textvmuted" >
                                Boş bırakılırsa bu değer seçilecek
                            </div>
                        </div>

                        < div className="p-3" >

                        </div>
                        {/*<div className="p-4" >*/}
                        {/*    Açıklama*/}
                        {/*    <input type="text" className="form-control form-control-sm" name="aciklama" defaultValue={rec.aciklama} onChange={(e) => updateRecord(rec, e)} />*/}
                        {/*    <div className="textvmuted" >*/}
                        {/*        Dosya hakkında açıklama yazabilirsiniz.*/}
                        {/*    </div>*/}
                        {/*</div>*/}



                    </div>
                </div>
                <div className="col-12 col-md-6" >
                    <div className="bg-white rounded-3" >
                        <div className="p-3 fw-bold border-bottom" >
                            Görüntüleme ve Yetkilendirme

                        </div>
                        <div className="p-4 row" >
                            <div class="col-3" >
                                <label class="form-check-label" for="inlineCheckbox1" >Sıra </label>
                            </div>
                            <div class="col-3" >
                                <input type="number" style={{ "background-color": "transparent" }} className="form-control form-control-sm " placeholder="Sıra" name="sira" defaultValue={rec.sira} onChange={(e) => updateRecord(rec, e)} />
                            </div>
                            <div class="col-5 p-0 textvmuted" >
                                Hangi sırada görünsün
                            </div>
                        </div>


                        <div className="p-4 row" >
                            <div class="col-3" >
                                <label class="form-check-label" for="inlineCheckbox1" >Zorunlu < /label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input me-2" name="reqired" defaultChecked={rec.reqired} onChange={(e) => updateRecord(rec, e)} />
                            </div>
                            <div class="col-7 p-0 textvmuted" >
                                Zorunlu Olarak yüklensin mı ?
                            </div>
                        </div>

                        <div className="p-4 row" >
                            <div class="col-3" >
                                <label class="form-check-label" for="inlineCheckbox1" >Aktif < /label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="active" defaultChecked={rec.active} onChange={(e) => updateRecord(rec, e)} />
                            </div>
                            <div class="col-7 p-0 textvmuted" >
                                Başvurularda görünsun mu ?
                            </div>
                        </div>
                        <div className="p-4 row" >
                            <div class="col-3" >
                                <label class="form-check-label" for="inlineCheckbox1" >Dilekçe < /label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="printable" defaultChecked={rec.printable} onChange={(e) => updateRecord(rec, e)} />
                            </div>
                            <div class="col-7 p-0 textvmuted" >
                                Başvuru dilekçesinde görünsun mu?
                            </div>
                        </div>
                        <div className="p-4 row" >
                            <div class="col-3" >
                                <label class="form-check-label" for="inlineCheckbox1" >Gizli < /label>
                            </div>
                            <div class="col-1" >
                                <input type="checkbox" className="form-check-input" name="gizli" defaultChecked={rec.gizli} onChange={(e) => updateRecord(rec, e)} />
                            </div>
                            <div class="col-7 p-0 textvmuted" >
                                Sadece Kontrolörler görecek
                            </div>
                        </div>

                        <div className="p-4 " >
                            Güncelleme Yetkisi


                            <Multiselect className="p-0"
                                options={roles}
                                placeholder="Rol"
                                emptyRecordMsg="Başka Kayıt Yok"
                                displayValue="name"
                                isObject={true}
                                selectedValues={rec.role != null ? rec.role.split(',').filter(r => roles.find(x => x.id == r) != null).map(r => roles.find(x => x.id == r)) : []}
                                onSelect={(selectedList, removedItem) => { rec.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on select event
                                onRemove={(selectedList, removedItem) => { rec.role = selectedList.length == 0 ? null : selectedList.map(rec => rec.id).join() }} // Function will trigger on remove event
                            />

                            < div class="textvmuted" >
                                Kimler tarafından değiştirilebilsin
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}