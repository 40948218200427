import { useLocation } from 'react-router-dom'
import React, { useState, useEffect } from 'react';
import { showErrorMessage, useGlobalState } from '../GlobalState.js';
import * as UserPermConstants from '../User/UserPermConstants';
import Multiselect from 'multiselect-react-dropdown';
import DenetimPermissionPanel from './DenetimPermissionPanel.js';
import ListAppTypePermission from './ListAppTypePermission.js';

import { ajaxFail } from '../GlobalState.js';
import KabulAppTypePermission from './KabulAppTypePermission.js';

export default function UserTypeData(props) {
    const [userdata, setUserData] = useState(props.userdata);
    const [role, setRole] = useGlobalState('role');
    const multiselectUserTypeRef = React.createRef();
    const multiselectPermRef = React.createRef();
    const [options, setOptions] = useState(null);
    const [rolelist, setRollist] = useState(null);
    const [denetimList, setDenetimList] = useState([]);

    useEffect(() => {
        fetchUserTypeList();
        fetchRoles();
        fetchDenetimList();
    }, [])


    const fetchRoles = () => {
        fetch("getrolelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    setRollist(value)
                })
            }
        });

    }

    const fetchDenetimList = () => {
        fetch("denetimlist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {

                    setDenetimList(value);
                })
            }
        });
    }

    const fetchUserTypeList = () => {

        fetch("getusertypelist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                response.json().then(value => {
                    var i = 0;
                    var opts = [];
                    for (i = 0; i < value.length; i++) {
                        opts.push(value[i].appType)

                    }
                    setOptions(opts.sort())

                })
            }
        });
    }


    const handleInputChange = (event) => {
        const target = event.target.name;
        const value = event.target.type === 'checkbox' ? event.target.checked : event.target.value;
        userdata[target] = value;

        if (event.target.type === 'checkbox' && event.target.id === "karaliste")
            document.getElementById("karalistesure").disabled = !event.target.checked;

        if (event.target.type === 'checkbox' && event.target.id === "gecicimuellif") {
            if (event.target.checked != true) {
                document.getElementById("gecicisure").value = "";
                userdata["gecicisure"] = "";
            }
            document.getElementById("gecicisure").disabled = !event.target.checked;

        }

        if (event.target.type === 'checkbox' && event.target.id === "denetimYetkisi" && value == true) {
            showErrorMessage("Yetki Turleri Denetim Yetkileri Panelinden Belirlenmelidir")
        }
        return true;
    }

    const onSelect = (selectedList, selectedItem) => {
        var clist = selectedList.join();
        userdata["userType"] = clist;

    }

    const onRemove = (selectedList, removedItem) => {
        var clist = selectedList.join();
        userdata["userType"] = clist;
       
    }



    return <div >
        <div className="row">
            <h5> Kullanıcı Tipi ve Yetkileri</h5>
        </div>



        {(options == null || rolelist == null) ?
            <div className="w-100 mt-4 mb-4 text-center align-items-center justify-content-center" ><div className="spinner-border" ></div></div>

            :
            <fieldset className=" ms-1 me-1 mt-3">
                <div className="row mb-1 mt-1 ">

                    <div className='col-3' >
                        <label htmlFor="Tel" className="form-label">Kullanıcı Tipi/Sıfatı</label>
                    </div>
                    <div className='col-8' >
                        <Multiselect className="p-0 ps-1 form-control-sm bg-white" id="kullanicitiplerim"
                            options={options}
                            placeholder="Kullanıcı Tipi"
                            emptyRecordMsg="Başka Kullanıcı Tipi Yok"
                            ref={multiselectUserTypeRef}
                            isObject={false}
                            onSelect={onSelect} // Function will trigger on select event
                            onRemove={onRemove} // Function will trigger on remove event
                            selectedValues={userdata.userType != null ? userdata.userType.split(',') : []}
                        />
                    </div>
                </div>
                <div className='row mt-3' hidden={role.indexOf(UserPermConstants.Kullanici_Rolleri) == -1}>
                    <div className="col-3">
                        <label htmlFor="roles" className="form-label">Rol</label>
                    </div>
                    <div className="col-8">
                        <select className="form-select form-select-sm" name="role" onChange={handleInputChange} >
                            <option selected={userdata == null || userdata.role == null} value={null}></option>
                            {
                                rolelist.map(x => <option value={x.id} selected={userdata != null && x.id == userdata.role}>{x.name}</option>)
                            }
                        </select>
                    </div>
                </div >
                <div className='row mt-3' hidden={role.indexOf(UserPermConstants.Kullanici_Rolleri) == -1}>
                    <div className="col-5">
                        <label htmlFor="roles" className="form-label">Sabit İmzacı</label>
                    </div>
                    <div className="col-6 " >
                        <input className="form-check-input" type="checkbox" name="sabitImzaci" id="sabitImzaci" defaultChecked={userdata.sabitImzaci} onChange={handleInputChange} />

                    </div>
                </div >
                <div className='row mt-1' hidden={role.indexOf(UserPermConstants.Gizli_Aktiviteleri_Gorme) == -1}>
                    <div className="col-5">
                        <label htmlFor="roles" className="form-label">Başvuru Geçmiş Aktivite Kaydı Gizleme</label>
                    </div>
                    <div className="col-6 " >
                        <input className="form-check-input" type="checkbox" name="hideEvents" id="hideEvents" defaultChecked={userdata.hideEvents} onChange={handleInputChange} />

                    </div>
                </div >
            </fieldset>
        }

        <div hidden={role.indexOf(UserPermConstants.Kullnici_Kara_Liste) == -1}>
            <div className='row mt-2' >
                <div className='col-2 ps-3' >
                    Kara liste
                </div>
                <div className='col-1' >
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="karaliste" id="karaliste" defaultChecked={userdata.karaliste} onChange={handleInputChange} />
                    </div>

                </div>
                <div className='col-3 ' >
                   
                        <input className="form-control form-control-sm" type="date" name="karalistesure" id="karalistesure" defaultValue={userdata.karalistesure} placeholder="Geçerlilk süresi (örnek 2022/01/01)" onChange={handleInputChange} disabled={!userdata.karaliste} />
                    
                </div>
                <div className='col-6 textvmuted small' >
                    tarihine kadar sisteme giriş yapamaz
                </div>
            </div>
            <div className='row mt-2 mb-3 ' >
                <div className='col-2 ps-3' >
                    Beyaz Liste
                </div>
                <div className='col-1' >
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" name="gecicimuellif" id="gecicimuellif" defaultChecked={userdata.gecicimuellif} onChange={handleInputChange} />
                    </div>

                </div>
                <div className='col-3 ' >
                    <div >
                        <input className="form-control form-control-sm" type="date" name="gecicisure" id="gecicisure" defaultValue={userdata.gecicisure} placeholder="Geçerlilk süresi (örnek 2022/01/01)" onChange={handleInputChange} disabled={!userdata.gecicimuellif} />
                    </div>
                </div>
                <div className='col-6 textvmuted small' >
                            tarihine kadar başvuru yapabilir veya müellif olarak eklenebilir
                </div>
            </div>
        </div>



        <div hidden={role.indexOf(UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme) == -1} >
            <div className='row ms-1 me-1 mt-3 mb-4 pt-2 border bg-light' >
                <DenetimPermissionPanel username={userdata.userName} denetimList={denetimList} key={denetimList} />
            </div>
        </div>



        <div hidden={role.indexOf(UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme) == -1} >
            <div className='row ms-1 me-1 mt-3 mb-4 pt-2 border bg-light' >
                <ListAppTypePermission username={userdata.userName} denetimList={denetimList.filter(x=>x.isadimi!=true)} key={denetimList} />
            </div>
        </div>

        <div hidden={role.indexOf(UserPermConstants.Kullanici_Denetim_Yetkilerini_Editleme) == -1} >
            <div className='row ms-1 me-1 mt-3 mb-4 pt-2 border bg-light' >
                <KabulAppTypePermission username={userdata.userName} denetimList={denetimList.filter(x => x.isadimi != true)} key={denetimList} />
            </div>
        </div>


        <button type="submit" className="btn btn-primary btn-sm mt-2">Güncelle</button>

    </div>


}




