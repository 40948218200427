import React, { useState, useEffect } from 'react';
import 'bootstrap/dist/js/bootstrap.min.js'
import { ajaxFail, isEmpty, showErrorMessage } from '../GlobalState.js';
import $ from 'jquery';
import Register2 from "./Register2.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { useGlobalState, } from '../GlobalState.js';

export default function Register1(props) {
    

    useEffect(() => {
     
    }, [])




   



    $('#Name,#Family').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-ZğüçşöıÖÇŞİĞÜ \.]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });


    $('#UserName').on('keypress', function (event) {
        var regex = new RegExp("^[a-zA-Z0-9_\.]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });

    $('#TC').on('keypress', function (event) {
        var regex = new RegExp("^[0-9]+$");
        var key = String.fromCharCode(!event.charCode ? event.which : event.charCode);
        if (!regex.test(key)) {
            event.preventDefault();
            return false;
        }
    });

    const hasNumber = (myString) => {
        return /\d/.test(myString);
    }
    const hasChar = (myString) => {
        return /[a-zA-Z]/g.test(myString);
    }



    const [role, setRole] = useGlobalState('role');
    const zorunlo = role.indexOf(UserPermConstants.Yeni_Kullanici_Kaydinda_Zorunlu_Alanlari_Bos_Birakabilme) == -1;


    const handleInputChange = (event) => {
        const target = event.target;
        var value = target.type === 'checkbox' ? target.checked : target.value.trim();
        window.userdata[target.name] = value;
    }

    const nextPage = (event) => {
        if (window.userdata.password == null || window.userdata.password.length < 8) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (hasNumber(window.userdata.password) == false || hasChar(window.userdata.password) == false) {
            showErrorMessage("Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr");
            return;
        }
        if (document.getElementById("Password").value != document.getElementById("Password2").value) {
            showErrorMessage("Girilen şifreler aynı değil");
            return;
        }
        if (isEmpty(window.userdata.birthdate) ) {
            showErrorMessage("Doğum Tarihi Boş Olamaz");
            return;
        }
        if (isEmpty(window.userdata.name)) {
            showErrorMessage("İsim Boş Olamaz");
            return;
        }
        if (isEmpty(window.userdata.family)) {
            showErrorMessage("İsim Boş Olamaz");
            return;
        }
        if (isEmpty(window.userdata.tc) || window.userdata.tc=="direct") {
            showErrorMessage("TC Boş Olamaz");
            return;
        }
        if (isEmpty(window.userdata.email)) {
            showErrorMessage("Email Adresi Boş Olamaz");
            return;
        }
        if (isEmpty(window.userdata.tel)) {
            showErrorMessage("Telefon Numarası Boş Olamaz");
            return;
        }
        if (isEmpty(window.userdata.userName)) {
            showErrorMessage("Kullanıcı Adı Boş Olamaz");
            return;
        }

        props.changeComponent(<Register2  changeComponent={props.changeComponent} />)

    }
    

    const onSubmitSave = (e) => {
        window.userdata.bildirimTercihleri = "EİmzaSira";  //initlaly all users have this activated
        fetch("updateuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(window.userdata)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response)
            }
            else {
                props.changeComponent(<Register2  changeComponent={props.changeComponent} />)

            }
        });
    }

    return <div className="mt-3">
        <div id="myscript"></div>
        <div className="row" >
            <div className="ms-2 me-2 p-0 col-md-3 col-12 text-primary border-primary fw-bold border-bottom border-3 " > 1. Kişisel Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " >2. Mesleki Bilgiler
            </div>
            <div className="ms-3 me-2 p-0 col-md-3 col-12   border-bottom border-3 " > 3. Diğer
            </div>
        </div>
        <div className="row m-3" >
            <div className="col-12" >
                <div className=" p-1" >
                  

                        <div>
                            <div className="mt-1 row mb-2" >
                                <div className="col-12 col-md-5">
                                    <label className="form-label">TC (şirketlerde yetkilinin TC'si yazılmalıdır) </label>
                                <input type="text" className="form-control" pattern="[0-9]{1,20}" maxLength="15" id="TC" placeholder="TC" name="tc" onChange={handleInputChange} defaultValue={window.userdata.tc == "direct" ? "" : window.userdata.tc} disabled={window.edevletlogin == true && role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) == -1} />
                                </div>
                                <div className="col-12 col-md-5">
                                    <label className="form-label">Doğum Tarihi </label>
                                <input type="date" max="2999-12-31" className="form-control" id="birthdate" placeholder="Doğum Tarihi" name="birthdate" required={zorunlo} onChange={handleInputChange} defaultValue={window.userdata.birthdate} />
                                </div>

                            </div>

                            <div className="mt-1 row  mb-2" >
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Name" className="form-label m-0">Adı</label>
                                <input type="text" className="form-control" id="Name" pattern="[a-zA-Z0-9ğüçşöıÖÇŞİĞÜ ]+$" placeholder="Adı" name="name" required={zorunlo} onChange={handleInputChange} defaultValue={window.userdata.name} disabled={window.edevletlogin == true && role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) == -1} />

                                </div>
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Family" className="form-label m-0">Soyadı</label>
                                <input type="text" className="form-control" id="Family" pattern="[a-zA-Z0-9ğüçşöıÖÇŞİĞÜ ]+$" placeholder="Soyadı" name="family" required={zorunlo} onChange={handleInputChange} defaultValue={window.userdata.family} disabled={window.edevletlogin == true && role.indexOf(UserPermConstants.Kullanici_Kayit_Formu) == -1} />
                                </div>
                            </div>

                            <div className="mt-1 row  mb-2" >
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Tel" className="form-label m-0">GSM No (Başında 0 olmadan)</label>
                                <input type="text" pattern="[5-5]{1}[0-9]{2}[0-9]{7}" maxLength="10" placeholder="5__ ___ ____" className="form-control" id="Tel" name="tel" required={zorunlo} onChange={handleInputChange} defaultValue={window.userdata.tel} />

                                </div>
                                <div className="col-12 col-md-5">
                                    <label htmlFor="Tel" className="form-label m-0">Email</label>
                                <input type="email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2, 4}$" maxLength="50" className="form-control" id="email" placeholder="email" name="email" required={zorunlo} onChange={handleInputChange} defaultValue={window.userdata.email} />
                                </div>
                            </div>


                            <div className="mt-1 row  mb-2" >
                                <div className="col-12 col-md-5">
                                    <label htmlFor="UserName" className="form-label m-0">Kullanıcı Adı</label>
                                <input type="text" className="form-control" id="UserName" maxLength="50" placeholder="" name="userName" onChange={handleInputChange} required defaultValue={window.userdata.userName} />
                                </div>

                                <div className="col-12 col-md-6 pt-4">
                                    <label className="form-label me-3">İletişim Yöntemi</label>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" name="sendMessage" id="sendMessage" defaultChecked={window.userdata.sendMessage} onChange={handleInputChange} defaultValue={window.userdata.sendMessage} />
                                        <label className="form-check-label m-0" for="sendMessage">
                                            Mesajlarım
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" name="sendEmail" id="sendEmail" defaultChecked={window.userdata.sendEmail} onChange={handleInputChange} defaultValue={window.userdata.sendEmail} />
                                        <label className="form-check-label m-0" for="sendEmail">
                                            Email
                                        </label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                    <input className="form-check-input" type="checkbox" name="sendSMS" id="sendSMS" defaultChecked={window.userdata.sendSMS} onChange={handleInputChange} defaultValue={window.userdata.sendSMS} />
                                        <label className="form-check-label m-0" for="sendSMS">
                                            SMS
                                        </label>
                                    </div>

                                </div>





                            </div>

                            <div className=" row  mb-2" id="passwordrow" >
                                <div className="col-12 col-md-5">
                                    Şifre
                                <input type="password" maxLength="30" className="form-control" id="Password" placeholder="Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr" name="password" required onChange={handleInputChange} defaultValue={window.userdata.password} />
                                </div>
                                <div className="col-12 col-md-5">
                                    Şifre - Tekrar
                                <input type="password" maxLength="30" className="form-control" id="Password2" placeholder="Şifre en az 8 karakter, 1 harf ve 1 rakam içermelidr" name="password" required onChange={handleInputChange} defaultValue={window.userdata.password} />
                                </div>

                            </div>

                           
                            <div className="row mt-3 border-top pt-2" >
                            <div className="col-12 col-md-12 text-end">
                                <button type="button" className="btn btn-sm btn-primary mb-2 " id="devambutton" onClick={nextPage }>Devam</button>
                                </div>
                            </div>
                        </div>
                  
                </div>
            </div>
        </div >
    </div >
}



