import React, { useState, useEffect } from 'react';
import axios from "axios";
import { setVisible, showErrorMessage } from '../GlobalState.js';

export default function UploadFile(props) {
    var selectedfile;
    const setfile = (e) => {
        selectedfile = e.target.files[0];
        var formData = new FormData();
        formData.append("file", selectedfile);
        formData.append("caption", props.username);
        formData.append("typeID", 0);
        formData.append("appid", 0);


        var loadspin1 = document.getElementById(props.spinner);
        if (loadspin1)
            loadspin1.hidden = false;


        axios.post(
            props.target,
            formData,
            {
                headers: {
                    "Content-type": "multipart/form-data"
                },
            }
        )
            .then(async res => {
                if (loadspin1)
                    loadspin1.hidden = true;
                // this.setState({ status: "ok" }); 
                if (props.setFiles != null) props.setFiles(res);
                if (props.setSumbitResult != null)
                    props.setSumbitResult(res);
            })
            .catch(err => {
                showErrorMessage(err)
            })
    }



    return (
        <div >

            <label for="formFile" className="form-label text-primary pt-2" role='button'><i class="fa fa-upload"></i> </label>
            <input class="form-control form-control-sm" hidden type="file" id="formFile" placeholder={props.filelabel}
                onChange={(e) => { setfile(e); }}
            />

        </div>
    );


}
