import React, { useState, useEffect } from 'react';
import { ajaxFail, isEmpty } from '../GlobalState.js';


export default function DuyuruPanel(props) {

    const [listData, setListData] = useState([]);
    const colors = ["#f2fff2", "#ffffff"]
    //const colors = ["#fff8dd", "#e8fff3", "#fff5f8", "#f8f5ff"]
    var duyurucount = listData.length;
    const updateRecord = (rec, event) => {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        rec[name] = value;
    }


    const fetchList = () => {
        fetch("getduyurulist", {
            method: 'GET',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setListData(value);
                })
            }
        });
    }




    const renderTable = (tabledata) => {
        var mytable =
            <div className="overflow-auto vdscrollbar" style={{ maxHeight: "280px" }}>
                {tabledata.map((apprec, idx) =>
                    <div className="row pt-2 ms-2 me-2 rounded-3" style={{ "background-color": colors[idx % colors.length] }} key={apprec.id}>
                        <div className="col-6" >
                            <img className="rounded-circle" style={{ width: "32px", height: "32px" }} src={"/getuserpic?user=" + apprec.userName} /> {apprec.temp}
                        </div>
                        <div className="col-6 text-end" >
                            {apprec.postDate}
                        </div>
                        <div className="col-12 " > <div className="mt-1" dangerouslySetInnerHTML={{ __html: apprec.message }} /></div>
                        <div className="col-12 " >
                            {isEmpty(apprec.fileName) == false &&
                                <a className="text-decoration-none" href={'getduyurufile?id=' + apprec.id} download={apprec.fileName}> {apprec.fileName} </a>
                            }

                        </div>

                    </div>

                )}
            </div>
        return (mytable);
    }



    useEffect(() => {
        fetchList();
    }, [])


    var listTable = "";
    if (listData != null) {
        listTable = renderTable(listData);
    }
    else
        listTable = renderTable([]);

    return (
        <div className="  rounded-3  shadow-sm">
            <div className="row">
                <div className="col-md-7 col-12">
                    <h6 style={{ color: "#0098db" }}>Duyurular</h6>
                </div>
                <div className="col-md-5 col-12 text-end text-secondary ">{duyurucount} duyuru</div>
            </div>
            {listTable}
        </div>

    );


}