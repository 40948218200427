import React, { useState, useEffect } from 'react';
import { Navigate } from 'react-router-dom'
import { isEmpty, sendpost, showErrorMessage, useGlobalState, sendget, getHtmlTag } from '../GlobalState.js';
import Register1 from "./Register1.js";
import ForgotPassword from "./ForgotPassword.js";
import aes from 'crypto-js/aes';
import crypto from 'crypto-js';
import { ajaxFail } from '../GlobalState.js';
import { useSearchParams } from 'react-router-dom'
import VersionData from "../version.json";



export default function TwoStep(props) {
    const [searchParams, setSearchParams] = useSearchParams();
    var user_d = searchParams.get("tc");
    var verify = searchParams.get("code");
    const [activepannel, setActivepannel] = useState(null);
    const email = searchParams.get("email");
    const sms = searchParams.get("sms");
    const emsm = searchParams.get("emailsms");

    var message = "Lütfen " + (props.channel == "email" ? " Email Adresinize" : (props.channel == "sms" ? "Telefon Numaranıza" : "Email ve ya Telefon Numaranıza")) + " gönderilen kodu girniz."



    useEffect(() => {


    }, []);



    const sendcode = (e) => {
        var v = getHtmlTag("stepcode", "value");
        if (isEmpty(v)) {
            showErrorMessage("Kod boş olamaz");
            return;
        }
        sendpost("submittwostepcode", v + "#" + props.logindata.UserName + "#" + props.logindata.Password, "", (response) => {

            response.text().then(value => {
                value = value.replaceAll('"', '');
                const Buffer = require("buffer").Buffer;
                value = Buffer.from(value, 'base64').toString('utf-8')
                var k = value.substring(0, 16)
                value = value.substring(16);
                var c = crypto.AES.encrypt(JSON.stringify({ value }), k).toString();
                props.setToken(k + c);
            })
        }
            , (response) => {
                response.text().then(value => {
                    if (value.length < 10) {
                        window.location.href = "/";
                        return;
                    }
                    else
                        showErrorMessage(value)

                })
                
        })

    }





    return <div className="mt-5 pt-5">
        <div className="row">
            <div className="col-12 col-lg-3">
            </div>
            <div className="col-12 col-lg-6 text-center">
                <label htmlFor="UserName" className="form-label text-muted "><b>{message}</b></label>
            </div>

        </div>
        <div className="row">
            <div className="col-12 col-lg-4">
            </div>
            <div className="col-12 col-lg-4 text-center">
                <input type="text" className="form-control form-control-sm" id="stepcode" />
                <button className="btn btn-sm btn-primary mt-2" onClick={sendcode}> Gönder</button>
            </div>
        </div>

    </div >


}


