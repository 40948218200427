import React, { useState, useEffect } from 'react';
import * as UserPermConstants from '../User/UserPermConstants';
import { isEmpty, showConfirmMessage, showErrorMessage, useGlobalState, maskStringWithStar, sendpost } from '../GlobalState.js';
import { ajaxFail } from '../GlobalState.js';
import PDFView from '../Application/PDFView';
import EImzaDetails from './EImzaDetails';
import EImzaTekrar from './EImzaTekrar';
import EImzaList from './EImzaList';
import EimzaOnaylarim from './EimzaOnaylarim';




export default function EImza(props) {
    const [e, setEimza] = useState(props.Eimza);
    const [imzalayanlist, setimzalayanlist] = useState([]);
    const [role, setRole] = useGlobalState('role');
    const [userName, setuserName] = useGlobalState('userName');
    var [guncelnecekEimza, setGuncelnecekEimza] = useState(null);



    var changeComponent = props.changeComponent;
    if (changeComponent != null) {
        window.changeComponent = changeComponent;
    }
    else
        changeComponent = window.changeComponent;


    useEffect(() => {
        if (props.showImzaciList == true)
            getimzalistesi();

    }, [])

    var source = < EImzaList appData={props.appData} targetEimza={e} setActivePanel={props.setActivePanel} />



    const getimzalistesi = () => {
        document.getElementById("imzalistspin" + e.id).hidden = false;
        fetch("imzalayanlist", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(e.id + "")
        }).then(async response => {
            document.getElementById("imzalistspin" + e.id).hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                response.json().then(value => {
                    setimzalayanlist(value.sort((a, b) => a.sira - b.sira));
                })
            }
        });
    }


    const doremove = (id, status) => {
        fetch("seteimzaremovestatus?eimzaid=" + id + "&status=" + status, {
            method: 'GET',
            headers: {
                'Accept': 'text/html'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                /*setEimza({ ...e, "removed": status == 1 })
            props.updateEimza({ ...e, "removed": status == 1 }, imzalayanlist)*/
                props.fetcheimzatasks();
            }
        })
    }
    const rejectEimza = (rec, rejectEimza) => {
        e.rejectText = rejectEimza;
        sendpost("rejecteimza", e, "imzalistspin" + e.id, (response) => {
            response.json().then(value => {
                setEimza(value);
            })
        })
    }



    const getImzaButtons = (e, rec) => {
        //if (isEmpty(e.serviceProvider) || e.serviceProvider=="Arksigner") {
        //    return <><button className={"btn   btn-sm  rounded ps-1 pe-1 me-1 " + (rec.uuid != null ? "btn-secondary" : "btn-primary")} onClick={() => imzabaslat(e, rec)}>
        //        Süreci_Başlat
        //    </button>
        //        <span class="ms-1 spinner-border spinner-border-sm" role="status" hidden key={rec.uuid}></span>

        //        {
        //            rec.uuid != null &&
        //            <a href={"https://via.arksigner.com/Imzala.aspx?documentUUID=" + rec.uuid} className="btn  btn-sm btn-primary rounded text-decoration-none text-white" target="_blank">  İmzala</a>
        //        }
        //        {role.indexOf(UserPermConstants.Tarife_Cetveli) > -1 ? <button className="btn  btn-sm btn-primary rounded text-decoration-none text-white" onClick={() => changeComponent(<EimzaOnaylarim source={source} changeComponent={changeComponent} setActivePanel={props.setActivePanel} appData={props.appData} eimza={e} imzalayanlist={imzalayanlist} imzalayan={rec} />)}> Onaylarımdan İmzala</button> : ""}
        //    </>


        //}
        if (props.appData.archived == true)
            return "Arşivelenen Dosya İmzalanamz";

        if (e.rejected == true) {
            return "";
        }

        return <><button className="btn  btn-sm btn-primary rounded text-decoration-none text-white"
            onClick={() => changeComponent(<EimzaOnaylarim source={source} changeComponent={changeComponent}
                setActivePanel={props.setActivePanel} appData={props.appData} eimza={e}
                imzalayanlist={imzalayanlist} imzalayan={rec} />)}> İmzala
        </button>
            <button className="ms-1 btn  btn-sm btn-danger rounded text-decoration-none text-white"
                onClick={() => showConfirmMessage("E-İmzayı Redetmek istediğinizden eminmisniz?", "İmza süreci durdurulacaktır", "Evet", "Hayır", () => {
                    setTimeout(() => {
                        let rejecttext = prompt("Lütfen reddetme gerekçesini yazınız");
                        if (isEmpty(rejecttext)) {
                            showErrorMessage("Red gerekçesi boş olamaz");
                            return;
                        }
                        rejectEimza(rec, rejecttext)
                    }, 300);

                })}>
                Reddet
            </button>
        </>


    }

    const seteimzaremovestatus = (id, status) => {
        if (status == 1)
            showConfirmMessage("E-İmzayı Silmek İstediğinizden Emin misiniz?", "", "Evet", "Hayır", () => doremove(id, status));
        else
            doremove(id, status)

    }


    const imzaiptal = () => {

        fetch("canceleimza?id=" + e.id, {
            method: 'GET',
            headers: {
                'Accept': 'text/html'
            }
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                setEimza({ ...e, imzatarih: UserPermConstants.EIMZA_IPTAL });
                props.fetcheimzatasks();
            }
        });
    }

    const imzabaslat = (e, rec) => {
        var posrdata = { eimzaid: e.id, username: rec.userName, appid: e.appId }
        document.getElementById("imzalabuttonarea" + rec.id).children[1].hidden = false;
        fetch("startsigntaskforuser", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(posrdata)
        }).then(async response => {
            document.getElementById("imzalabuttonarea" + rec.id).children[1].hidden = true;
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                getimzalistesi();
            }
        });
    }

    const updateEimza = (e) => {

        sendpost("updateeimza", e, "imzalistspin" + e.id, (response) => {
            response.json().then(async data => {
                setEimza(data);
            });
        })

    }



    var minsira = Number.MAX_SAFE_INTEGER;
    imzalayanlist.filter(x => isEmpty(x.imzatarih)).forEach(x => minsira = Math.min(minsira, x.sira))

    return <div className="border p-3 pt-2 bg-light mt-4 rounded" id={"EimzaRec" + e.id} >
        {e.removed != true && <> <div className="row" key={e.imzatarih} >
        </div>
            {e.eimzaTextPos == false &&
                <div className="row" key={e.imzatarih} >
                    <div className="col-12 text-danger small fw-bold mb-1"> Yüklediğiniz belgede &#34;&#123;e-imza &#34; ve &#34;e-imza&#125;&#34; ifadeleri bulunamamıştır. Yasal e-imza metni ve QR kod belgenin altına yerleştirilecektir.</div>
                </div>
            }</>
        }
        <div className="row " >
            {e.removed != true ? <div className="col-12 col-md-6 ps-0">
                {/* <span className="badge bg-info  mt-0">{e.userFileId != null ? (e.userFileId):""}</span>*/}

                <a href={'geteimzaoriginaldosya?eimzaid=' + e.id} className="text-decoration-none btn  btn-sm btn-link  p-0 text-start"
                    target="_blank" download="imzalanacak_evrak.pdf">
                    <span className="fw-bold  h6 "> {e.userFileId != null ? (e.userFileId + " - ") : ""}
                        {e.surecname} ({"id: " + e.id})
                    </span>

                </a>
                {e.veriGirisindenSonraIndirme == true && isEmpty(e.veriGirisTarihi) &&
                    <sup className="badge rounded-pill bg-danger">
                        Veri Girişi Bekleniyor
                    </sup>}
                {e.rejected == true &&<div>
                    <span className="text-danger ms-1 fw-bold">
                        Eimza {e.rejectedBy} tarafından Rededildi ({e.rejectText})
                    </span>
                    {e.userName == userName && <button className="btn btn-success btn-sm ms-1 p-1" onClick={() => { e.rejected = false; updateEimza(e); }}>Yeniden Devam Et</button>}
                </div>}
            </div> :
                <div className="col-12 col-md-6">
                    {role.indexOf(UserPermConstants.Imza_Silme) > -1 &&
                        <>
                            <span className="fw-bold  h6 "> {e.userFileId != null ? (e.userFileId + " - ") : ""}
                                {e.surecname} ({"id: " + e.id})
                            </span>
                         <button onClick={() => seteimzaremovestatus(e.id, 0)} className="btn btn-sm btn-success p-1 ms-1 ps-2 pe-2"
                                target="_blank"> Geri Al</button></>
                    }
                </div>
            }

            <div className="col-12 col-md-6 text-end ">
                {imzalayanlist.length > 0 && <span className="fw-bold text-primary me-2" id="yeniCounter">
                    <i className={"pe-1 fa fa-user" + (imzalayanlist.length > 1 ? "s" : "")} style={{ fontSize: "18px" }} ></i>{imzalayanlist.length}
                </span>}
                <div class="btn-group">
                    <button type="button" onClick={() => getimzalistesi()} className="rounded-1 btn btn-sm btn-primary me-1  shadow-none"
                        target="_blank">
                        İmza Listesi
                    </button>


                    <div className="dropdown">
                        <button class="btn btn-primary btn-sm dropdown-toggle shadow-none" type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                            İşlemler
                        </button>
                        <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                            {e.imzatarih != UserPermConstants.EIMZA_IPTAL && e.removed != true && <>
                                <li>
                                    {isEmpty(e.imzatarih) == false && <>
                                        {(e.veriGirisindenSonraIndirme == true && isEmpty(e.veriGirisTarihi) && role.indexOf(UserPermConstants.Imza_Veri_Girisi) == -1) ?
                                            <span className="text-secondary ms-3"><span className="fa fa-cloud-download" style={{ "font-size": "18px" }}></span> Veri Girişi Bekleniyor</span>
                                            : <a className="dropdown-item text-success " href={'getimzalidosya?eimzaid=' + e.id} download="eimza.pdf"><i className="fa fa-cloud-download" style={{ "font-size": "18px" }}></i> İmzalı Dosya İndir   </a>
                                        }</>
                                    }
                                </li>
                                <li>

                                    {role.indexOf(UserPermConstants.Imza_Sertifikali) > -1 && <>
                                        {(e.veriGirisindenSonraIndirme == true && isEmpty(e.veriGirisTarihi) && role.indexOf(UserPermConstants.Imza_Veri_Girisi) == -1) ?
                                            <span className="text-secondary ms-3"><span className="fa fa-cloud-download" style={{ "font-size": "18px" }}></span> Veri Girişi Bekleniyor</span>
                                            :

                                            <a className="dropdown-item text-success " href={'getimzalidosya?eimzaid=' + e.id + '&sert=1'} download="eimza.pdf"><i className="fa fa-cloud-download" style={{ "font-size": "18px" }}></i> Sertifikalı Belge İndir</a>
                                        }
                                    </>
                                    }
                                </li>
                                {props.appData.archived != true && <>
                                    {role.indexOf(UserPermConstants.Imza_Sertifikali) > -1 && <li>
                                        <a className="dropdown-item text-success " href={'UpgradePades?id=' + e.id} download="eimza.pdf"><i className="fa fa-cloud-download" style={{ "font-size": "18px" }}></i> Zaman Damgası Al</a>
                                    </li>}


                                    <li>
                                        <button disabled={(role.indexOf(UserPermConstants.Imza_Guncelleme) == -1 && userName != e.downloadUrl)} className="dropdown-item " onClick={() => { props.setActivePanel(<EImzaTekrar source={source} setActivePanel={props.setActivePanel} appData={props.appData} eimza={e} imzalayanlist={imzalayanlist} VizeKontrol={null} />) }}> <span className="fa fa-list-ul" style={{ "font-size": "18px" }}>
                                        </span> İmzacıları Güncelle
                                        </button>
                                        {<li>
                                            <button disabled={(role.indexOf(UserPermConstants.Imza_Yerlestirme) == -1 && userName != e.downloadUrl)} className="dropdown-item " onClick={() => props.setActivePanel(<PDFView Eimza={e} setActivePanel={props.setActivePanel} appData={props.appData} />)} > <i class=" fa fa-file-pdf-o" style={{ "font-size": "18px" }} aria-hidden="true"></i> İmzayı Yerleştir</button>
                                        </li>}
                                        {role.indexOf(UserPermConstants.Imza_Veri_Girisi) > -1 && <li>
                                            <button className="dropdown-item " onClick={() => props.setActivePanel(<EImzaDetails eimza={e} setActivePanel={props.setActivePanel} appData={props.appData} />)}> <i class="fa fa-cog" style={{ "font-size": "18px" }} aria-hidden="true"></i> Veri Girişi Yap</button>

                                        </li>}

                                    </li>
                                    {(role.indexOf(UserPermConstants.Imza_Vize_Kontrol) > -1) && <li>

                                        {(e.veriGirisindenSonraIndirme == true && isEmpty(e.veriGirisTarihi)) ?
                                            <span className="text-secondary ms-3"><span className="fa fa-user" style={{ "font-size": "18px" }}></span> Veri Girişi Bekleniyor</span>
                                            :
                                            <button className="dropdown-item " onClick={() => { props.setActivePanel(<EImzaTekrar source={source} setActivePanel={props.setActivePanel} appData={props.appData} eimza={e} imzalayanlist={imzalayanlist} VizeKontrol={true} />) }}>
                                                <span className="fa fa-user" style={{ "font-size": "18px" }}></span>  Vize Kontrolü</button>

                                        }
                                    </li>}
                                </>}


                            </>

                            }
                            {props.appData.archived != true && <>
                                <li>
                                    {role.indexOf(UserPermConstants.Imza_Silme) > -1 && isEmpty(e.imzatarih) == false && e.removed != 1 &&
                                        <button onClick={() => seteimzaremovestatus(e.id, 1)} className="dropdown-item text-danger" target="_blank" title="Sil"> <span className="fa fa-trash-o" style={{ "font-size": "18px" }}>

                                        </span> Sil</button>
                                    }
                                </li>

                                <li>

                                    {role.indexOf(UserPermConstants.Imza_Silme) > -1 && isEmpty(e.imzatarih) && e.basvuruCiktisi != true &&
                                        <button onClick={() => showConfirmMessage("E-İmza Süreci İptal Edilsin Mı?", "Bu işlem geri döndürülemez", "Evet", "Hayır", imzaiptal)} className="dropdown-item text-danger" target="_blank"><i class=" 	fa fa-minus-circle" style={{ "font-size": "18px" }} aria-hidden="true"></i> İptal Et</button>
                                    }

                                </li></>}



                        </ul>
                    </div>
                </div>
                <div id={"downloadbuttons" + e.id}>


                </div>

            </div>

        </div>

        <div className="row mt-1  rounded   text-secondary fw-bold" key={e.imzatarih} >
            <div className="col-12 col-md-4 ps-0" title={e.userName}>Başlama: {e.tarih}</div>
            <div className="col-12 col-md-4 ps-0">Bitiş: {e.imzatarih}</div>
            <div className="col-12 col-md-1 ps-0">{e.imzaType}</div>
            <div className="col-12 col-md-3 ps-0 pe-0 text-end">
                {e.removed != true && e.imzatarih != null && e.imzatarih != UserPermConstants.EIMZA_IPTAL && <div className="text-success fw-bold ">Süreç Tamamlanmıştır</div>}
            </div>



        </div><span class="ps-0 spinner-border spinner-border-sm" hidden id={"imzalistspin" + e.id} ></span>
        <div className="row">

            <div className="table-responsive">

                <table className='table  mt-3 table-vdstriped p-0' aria-labelledby="tabelLabel">
                    <tbody id="myTable">
                        {imzalayanlist.map((rec, idx) =>

                            <tr className={((rec.imzatarih != null && rec.imzatarih.length > 0) ? "text-success fw-bold" : "")}>
                                <td>
                                    {rec.sira}
                                </td>
                                <td>
                                    {rec.name} {rec.family}
                                </td>
                                <td>
                                    {maskStringWithStar(rec.tc)}
                                </td>
                                <td>
                                    {rec.rol}
                                </td>
                                <td>
                                    {rec.vizeKontrolTur}
                                </td>
                                <td>
                                    {rec.uuid}
                                </td>
                                <td>
                                    {rec.imzatarih}
                                </td>
                                {e.imzatarih != UserPermConstants.EIMZA_IPTAL && e.removed != true &&
                                    <td className="text-end" width="25%">
                                        {isEmpty(rec.imzatarih) == false ? "Imzaladı" :
                                            (rec.sira == minsira)
                                            && <div className="p-0" id={"imzalabuttonarea" + rec.id}>
                                                {(e.rejected == true && e.rejectedBy == rec.userName) ? <span className="text-danger fw-bold">Reddedildi</span> :
                                                    (userName == rec.userName ? getImzaButtons(e, rec)    : UserPermConstants.EIMZA_BEKLENIYOR)}
                                            </div>}


                                    </td>}

                            </tr>
                        )}
                    </tbody>
                </table>
            </div>

        </div>

        <div class="modal  fade" id={"eimzaGuncellModal" + e.id} tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog modal-xl">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" id="exampleModalLabel">Eimzayı Güncelle</h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" onClick={getimzalistesi}></button>
                    </div>

                    {guncelnecekEimza != null && <div class="modal-body">
                        <div class="row">
                            <div class="col-12">
                                E-İmza ID:  {guncelnecekEimza[0].id}
                            </div>
                        </div>
                        <EImzaTekrar appData={props.appData} eimza={guncelnecekEimza[0]} imzalayanlist={guncelnecekEimza[1]} VizeKontrol={guncelnecekEimza[2]} />
                    </div>}
                    <div class="modal-footer">
                        <button type="button" class="btn btn-sm btn-primary" data-bs-dismiss="modal" onClick={getimzalistesi}>Bitti</button>
                        {/*<button type="button" class="btn btn-sm btn-primary">Güncelle</button>*/}
                    </div>
                </div>
            </div>
        </div>

    </div>
}
