import React, { useState, useEffect } from 'react';
import { isEmpty, sendpost, showConfirmMessage, showErrorMessage, useGlobalState } from '../GlobalState.js';
import FilesPanel from "./FilesPanel.js";
import * as UserPermConstants from '../User/UserPermConstants';
import { ajaxFail } from '../GlobalState.js';
import EImzaList from '../Eimza/EImzaList.js';
import EImzaTekrar from '../Eimza/EImzaTekrar.js';
import PDFView from './PDFView.js';
import EImzaDetails from '../Eimza/EImzaDetails.js';


export default function InlineFilePanel(props) {

    const [role, setRole] = useGlobalState('role');
    const [userName, setuserName] = useGlobalState('userName');
    const [uploadedFiles, setuploadedFiles] = useState(props.uploadedFiles);
    const [fileNameList, setfileNameList] = useState(props.fileNameList);
    const [fileType, setfileType] = useState(props.fileType);

    var appData = props.appData;

    var sharingDeadline = "";
    var sharingType = "";
    if (appData != null && isEmpty(appData.temp) == false && appData.temp.includes("#")) {
        sharingDeadline = appData.temp.split("#")[0];
        sharingType = appData.temp.split("#")[1];
    }



    const getFileType = (rec) => {
        if (fileNameList == null) return rec.typeId;
        for (var i = 0; i < fileNameList.length; i++)
            if (fileNameList[i].id == rec.typeId) {
                rec.typeName = fileNameList[i].Name;
                rec.kontrolorDosyasi = fileNameList[i].kontrolorDosyasi;
                return fileNameList[i].Name;
            }
        return rec.typeId;
    }




    const canDelete = (rec) => {
        if (rec.kontrolorDosyasi == true && role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) == -1)
            return false;

        if (role != null && role.indexOf(UserPermConstants.Dosya_Degistirme_Silme) > -1)
            return true;

        if (appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Taslak
            || appData.basvuruDurumu == UserPermConstants.Basvuru_Durumu_Duzeltme_Bekleniyor) {
            if (isEmpty(appData.sonDuzeltmeTalebiTarihi) || rec.tarih >= appData.sonDuzeltmeTalebiTarihi) {
                return true;
            }
        }

        return false;
    }



    const changeHideState = (rec) => {
        if (rec.hidden == true)
            rec.hidden = false
        else
            rec.hidden = true;

        fetch("updatefile", {
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(rec)
        }).then(async response => {
            if (!response.ok) {
                ajaxFail(response);
            }
            else {
                props.setParentFileList(uploadedFiles.filter(x => x.id > 0));  //just to maake new array
            }
        });

    }





    const getEimzaMenu = (filerec) => {

        var e = filerec.imzaRec;
        if (e == null)
            return "";
        var source = <FilesPanel appData={appData} showAll={true} setActivePanel={props.setActivePanel} />
        var ret = <div className="dropdown d-inline-block ms-1 " >
            <button title={(e.removed != true && e.imzatarih == null) ? "E-İmza Sürecinde" : "E-İmzalı"} className={"btn btn-sm dropdown-toggle p-0 ps-1 pe-1 " + ((e.removed != true && e.imzatarih == null) ? "btn-warning" : "btn-primary")} type="button" id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                E-İmza
            </button>
            <ul class="dropdown-menu shadow" aria-labelledby="dropdownMenuButton1">
                {e.imzatarih != UserPermConstants.EIMZA_IPTAL && e.removed != true && <>
                    {<li>
                        <button className={"dropdown-item " + (appData.id == e.appId ? "text-primary" : "text-warning")} onClick={() => {
                            var btns = document.getElementById("buttonsbox").querySelectorAll(".btn");
                            btns.forEach(x => { if (x.classList.contains("btn-link") == false) { x.classList.remove("btn-primary"); x.classList.add("text-muted") } })

                            var b = document.getElementById("EimzaButton");
                            if (b != null) {
                                b.classList.remove("text-muted")
                                b.classList.add("btn-primary")
                            }
                            /* document.getElementById("activepaneldiv").scrollTo(0, 0)*/
                            /* props.setActivePanel(< EImzaList appData={props.appData} targetEimza={e} setActivePanel={props.setActivePanel} />)*/
                            if (appData.id == e.appId) {
                                var popup = window.open("/");
                                popup.appData = props.appData;
                                popup.eimza = e;
                            }
                            else {
                                var rapp = appData.relatedApps.find(a => a.id == e.appId)
                                if (rapp == null) {
                                    showErrorMessage(e.appId + " numaralı başvuru bulunamadı.")
                                }
                                else {
                                    var popup = window.open("/");
                                    popup.appData = rapp;
                                    popup.eimza = e;
                                }

                            }

                            // popup.postMessage("The user is 'bob' and the password is 'secret'", "*")
                            // window.setTimeout(()=>popup.postMessage("The user is 'bob' and the password is 'secret'", "*"),2000);
                        }}>
                            <i class=" fa fa-edit" style={{ "font-size": "18px" }} aria-hidden="true"></i> E-İmza Kaydına Git</button>

                    </li>}
                    <li>

                        {isEmpty(e.imzatarih) == false && <a className="dropdown-item text-success " href={'getimzalidosya?eimzaid=' + e.id} download="eimza.pdf"><i className="fa fa-cloud-download" style={{ "font-size": "18px" }}></i> İmzalı Dosya İndir </a>}

                    </li>
                    {/*(role.indexOf(UserPermConstants.Imza_Guncelleme) > -1 || userName == filerec.userName) &&*/ <li>
                        {/*<button className="dropdown-item " data-bs-toggle="modal" data-bs-target={"#eimzaGuncellModal"+e.id} onMouseDown={() => { if (imzalayanlist.length == 0) { showErrorMessage("Eimza Listesini Güncellemek içim Önce İmza Listesi Açılmalıdır"); return; } setGuncelnecekEimza([e, imzalayanlist]) }}> <span className="fa fa-list-ul" style={{ "font-size": "18px" }}>*/}
                        {/*</span> Adayları Güncelle*/}
                        {/*</button>*/}

                        <button disabled={(role.indexOf(UserPermConstants.Imza_Guncelleme) == -1 && userName != filerec.userName)} className="dropdown-item " onClick={() => { props.setActivePanel(<EImzaTekrar source={source} changeComponent={props.changeComponent} setActivePanel={props.setActivePanel} appData={props.appData} eimza={e} VizeKontrol={null} />) }}> <span className="fa fa-list-ul" style={{ "font-size": "18px" }}>
                        </span> İmzacıları Güncelle
                        </button>

                        {/*(role.indexOf(UserPermConstants.Imza_Yerlestirme) > -1 || userName == filerec.userName) &&*/ <li>
                            <button className="dropdown-item " disabled={(role.indexOf(UserPermConstants.Imza_Yerlestirme) == -1 && userName != filerec.userName)} onClick={() => props.setActivePanel(<PDFView Eimza={e} setActivePanel={props.setActivePanel} appData={props.appData} />)} > <i class=" fa fa-file-pdf-o" style={{ "font-size": "18px" }} aria-hidden="true"></i> İmzayı Yerleştir</button>
                        </li>}

                    </li>}

                    {role.indexOf(UserPermConstants.Imza_Veri_Girisi) > -1 && <li>
                        <button className="dropdown-item " onClick={() => props.setActivePanel(<EImzaDetails eimza={e} setActivePanel={props.setActivePanel} appData={props.appData} />)}> <i class="fa fa-cog" style={{ "font-size": "18px" }} aria-hidden="true"></i> Veri Girişi Yap</button>

                    </li>}

                    {(role.indexOf(UserPermConstants.Imza_Vize_Kontrol) > -1) && <li>
                        <button className="dropdown-item " onClick={() => { props.setActivePanel(<EImzaTekrar changeComponent={props.changeComponent} source={source} setActivePanel={props.setActivePanel} appData={props.appData} eimza={e} VizeKontrol={true} />) }}> <span className="fa fa-user" style={{ "font-size": "18px" }}>
                        </span> Vize Kontrolü</button>
                    </li>}
                </>

                }
            </ul>
        </div>
        return ret;
    }

    const imzala = (rec) => {

        var buttonbox = document.getElementById("buttonsbox");
        if (buttonbox != null) {
            var btns = buttonbox.querySelectorAll(".btn");
            btns.forEach(x => { if (x.classList.contains("btn-link") == false) { x.classList.remove("btn-primary"); x.classList.add("text-muted") } })

            var b = document.getElementById("EimzaButton");
            b.classList.remove("text-muted")
            b.classList.add("btn-primary")
        }
        if (appData.id == rec.appId)
            props.setActivePanel(<EImzaList appData={appData} targetFile={rec} setActivePanel={props.setActivePanel} changeComponent={props.changeComponent} />)
        else {

            showConfirmMessage("Şu anda " + appData.id + " numaralı başvuruda bulunuyorsunuz. Ancak imzaya açmaya çalıştığınız dosya " + rec.appId + " numaralı başvuruya yüklenmiş.", "E-İmza kaydı " + rec.appId + " numaralı başvuruda açılacaktır.",
                "Devam Et", "İptal", () => {
                    fetch("getapp", {
                        method: 'POST',
                        headers: {
                            'Accept': 'application/json',
                            'Content-Type': 'application/json'
                        },
                        body: rec.appId
                    }).then(async response => {
                        if (!response.ok) {
                            ajaxFail(response);
                        }
                        else {
                            response.json().then(value => {
                                props.setActivePanel(<EImzaList appData={value} targetFile={rec} setActivePanel={props.setActivePanel} changeComponent={props.changeComponent} />)
                            })
                        }
                    });
                });

        }


    }


    const getVisibleState = (id) => {
        if (fileNameList == null) return false;
        for (var i = 0; i < fileNameList.length; i++)
            if (fileNameList[i].id == id) {
                if (fileNameList[i].kontrolorDosyasi != true)
                    return true;
                if (role.indexOf(UserPermConstants.Kontrolor_Dosyasi_Yukleme) > -1) return true;
                if (fileNameList[i].kontrolorDosyaYetki == null || fileNameList[i].kontrolorDosyaYetki.length == 0) return true;
                if (fileNameList[i].kontrolorDosyaYetki.split(',').some(x => role.indexOf("#" + x + "#") > -1)) return true;
                return false;
            }
        return false;
    }




    const restorefile = (rec) => {
        rec.removedBy = appData.securityToken;
        showConfirmMessage(getFileType(rec) + " dosyasını geri almak istediğinizden emin misiniz?",
            '', 'Evet', 'Hayır', () => {
                sendpost("restorefile", rec, "", () => {
                    rec.removed = false;
                    props.setParentFileList([...uploadedFiles]);


                })
            });
    }

    const removefile = (rec) => {
        rec.readers = appData.securityToken;
        showConfirmMessage(getFileType(rec) + " dosyasını silmek istediğinizden emin misiniz?",
            rec.imzaRec != null ? 'Dosya E-imzalı olduğu için E-imza kaydı da silinecektir !' : '', 'Evet', 'Hayır', () => {
                sendpost("removefile", rec, "", () => {
                    if (rec.removed == true) {
                        props.setParentFileList(uploadedFiles.filter(x => x.id != rec.id));
                    }
                    else {
                        rec.removedBy = userName
                        rec.removed = true;
                        rec.removedDate = "Az önce"
                        props.setParentFileList([...uploadedFiles]);
                    }

                })
            });
    }



    const isVisivble = (rec) => {
        try {
            if (userName == rec.userName)
                return true;
            if (appData.basvuruYapan == userName)
                return true;
            if (props.kontrolorList != null && props.kontrolorList.some(x => x.userName == userName))
                return true;

            if (role.indexOf(UserPermConstants.Havale_Yapma) > -1
                || role.indexOf(UserPermConstants.Basvuru_Liste) > -1            )
                return true;


            // müellif listesinde varsa dosyayı gostermiyoruz
            if (isEmpty(appData.müellifKullanıcıAdı) == false && appData.müellifKullanıcıAdı.includes(userName + " "))
                return false;
            if (rec.appId == appData.id || role.indexOf(UserPermConstants.Basvuru_Onaylama) > -1)
                return true;
            if (appData.relatedApps != null && appData.relatedApps.map(x => x.id).includes(rec.appId) && role.indexOf(UserPermConstants.Ilgili_Basvurulari_Gorme) > -1)
                return true;
            return false;
        }
        catch (ex) {
            console.log(ex);
        }
        return false;
    }



    const getImzaButton = (rec) => {
        var button = <i className="	fa fa-edit" style={{ "font-size": "18px" }} ></i>
        if (props.setActivePanel != null &&
            /*appData.id == rec.appId &&*/
            role != null && role.indexOf(UserPermConstants.Dosyalari_Eimzaya_Acima) > -1) {
            if (rec.caption != null && rec.caption.toUpperCase().endsWith(".PDF")) {
                if (rec.imzatarih != null) {
                    return <span className="text-success" title={"Belge Daha Önce İmzalanmıştır"}>
                        {button}
                    </span>
                }

                return <a className="text-decoration-none" href="#" title={"Imzala"} onClick={() => imzala(rec)}>
                    {button}
                </a>
            }
            else
                return <span className="text-warning" title={"Sadece PDF dosyaları imzalanabilir"}>
                    {button}
                </span>
        }
        return "";
    }

    const gettextColor = (rec) => {
        if (isEmpty(rec.readers) == false && rec.readers.includes("!"))  //deleted file from similar app
            return "text-danger fw-bold";
        if (rec.readers == null || rec.readers.includes(userName) == false)
            return "text-success";
        return "";
    }

    const gettitle = (rec) => {
        if (rec.readers == "!")  //deleted file from similar app
            return "Başvuru Silinmiş";
        return "";
    }

    const isVisible = (rec) => {
        if (props.showAllFileState == false && rec.appId != appData.id)
            return false;

        if (props.showHidden == true)
            return rec.hidden == true;
        else
            return rec.hidden != true;
    }

    return (
        <div className='table-responsive' style={{ "overflow-y": "visible !important" }}>
            <table className='table  table-striped   table-sm mb-1' aria-labelledby="tabelLabel" style={{ "fontSize": "14px" }}>
                <tbody>

                    {uploadedFiles.filter(x => (x.removed != true || props.showDeletedFiles == true) && isVisible(x) && (x.typeId == fileType || props.showHidden == true)).map((rec, idx) => getVisibleState(rec.typeId) &&
                        <tr className={(appData.id == rec.appId && (rec.readers == null || rec.readers.includes(userName) == false)) ? "fw-bold text-success searchitem" : "searchitem"}>
                            <td width="2%" className={(rec.readers == null || rec.readers.includes(userName) == false) ? "text-success " : ""}>
                                {idx + 1}

                            </td>
                            <td width="5%" className={gettextColor(rec)} title="Başvuru No">
                                {rec.appId}
                            </td>
                            <td width="3%" className={(rec.readers == null || rec.readers.includes(userName) == false) ? "text-success" : ""} title="Dosya No">
                                {rec.id}
                            </td>

                            <td width="20%" className={gettextColor(rec)} >
                                <span className={rec.removed == true ? "text-decoration-line-through text-danger" : ""}>
                                    {getFileType(rec)}
                                </span>

                            </td>
                            <td width="20%" className={gettextColor(rec)} >

                                <span className={rec.removed == true ? "text-decoration-line-through text-danger" : ""}>
                                    {rec.caption}
                                </span>


                            </td>
                            <td width="15%" className={(rec.readers == null || rec.readers.includes(userName) == false) ? "text-success " : ""}>
                                {rec.tarih}
                            </td>

                            <td width="10%" className={(rec.readers == null || rec.readers.includes(userName) == false) ? "text-success " : ""}>
                                <img className="rounded-circle" style={{ width: "28px", height: "28px" }} src={'/getuserpic?user=' + rec.userName} /><span className={(rec.readers == null || rec.readers.includes(userName) == false) ? "text-success" : ""}> {rec.userName} </span>
                            </td>
                            {rec.removed == true ?
                                <td width="25%" className="text-end text-danger fw-normal small">
                                    Silinmiş ({rec.removedBy} {rec.removedDate})
                                    {(userName.toLocaleLowerCase() == "admin" || userName.toLocaleLowerCase() == "universe") && < button className="btn btn-sm btn-link  text-decoration-none p-1" type="button" onClick={() => restorefile(rec)}>
                                        Geri al
                                    </button>}

                                </td>
                                :
                                < td width="25%" className="text-end">{
                                    isVisivble(rec) && <>

                                        {/*(rec.imzaid != null && rec.removed != true && rec.imzatarih != null && rec.imzatarih != "İptal Edildi") && <sup><a className="text-decoration-none align-middle" href={'getimzalidosya?eimzaid=' + rec.imzaid} download="eimza.pdf"><span class="ms-1 me-1 badge rounded-pill bg-primary" > E-imzalı</span></a></sup>*/}
                                        {/*(rec.imzaid != null && rec.removed != true && rec.imzatarih == null) && <sup><span class="ms-1 me-1 badge rounded-pill bg-warning" >İmza Süreci</span></sup>*/}
                                        {/*< a className="text-decoration-none align-middle" href={'getfile?id=' + rec.id + "&appid=" + rec.appId + "&sec=" + appData.securityToken} download={rec.caption}><i className="fa fa-cloud-download" style={{ "font-size": "18px" }}></i>  </a>*/}
                                        <a className="text-decoration-none align-middle" href={'getfile?id=' + rec.id + "&appid=" + rec.appId + "&sec=" + appData.securityToken} title="Aç" target="_blank"><i className="	fa fa-television" style={{ "font-size": "18px" }} ></i>  </a>

                                        {(isEmpty(sharingType) == true || sharingType == "Düzenleme") && appData.archived != true ? <>
                                            {canDelete(rec) && /*appData.id == rec.appId &&*/ <a href="/#" onClick={() => { removefile(rec) }} className="text-decoration-none me-1"><i className="fa fa-trash-o" style={{ "font-size": "18px" }}></i></a>}
                                            {role.indexOf(UserPermConstants.Dosya_Gizleme) > -1 && < a className="text-decoration-none align-middle" href="#" onClick={() => changeHideState(rec)} title={rec.hidden == true ? "Gizliliği Kaldır" : "Gizle"}><i className={rec.hidden == true ? "fa fa-eye" : "fa fa-eye-slash"} style={{ "font-size": "18px" }}></i>  </a>}
                                            {getImzaButton(rec)}
                                            {getEimzaMenu(rec)}
                                        </> : rec.imzaRec != null && isEmpty(rec.imzaRec.imzatarih) == false &&
                                        <a className="text-success " href={'getimzalidosya?eimzaid=' + rec.imzaRec.id} download="eimza.pdf"><i className="fa fa-cloud-download" title={"İmzalı Dosya İndir"} style={{ "font-size": "18px" }}></i>  </a>}

                                    </>}

                                </td>}
                        </tr>)}





                </tbody>
            </table>

        </div>
    );
}



